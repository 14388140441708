import { useEffect, useState } from 'react';
import { getPersistedState, setPersistedState, removePersistedState } from '../utils';

export default (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    const stickyValue = getPersistedState(key);
    return stickyValue ? stickyValue : defaultValue;
  });

  useEffect(() => {
    if (value !== defaultValue) {
      setPersistedState(key, value);
    } else {
      removePersistedState(key);
    }
  }, [key, value]);

  return [value, setValue];
};
