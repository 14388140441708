import React from 'react';
import { Badge, IconButton, Menu, Tooltip, MenuItem } from '@mui/material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { useScreenDetector, useTranslation } from '../../hooks';

const NotificationBell = ({ badgeContent }) => {
  const [notificationEl, SetNotificationEl] = React.useState(null);
  const handleCloseNotification = () => SetNotificationEl(null);
  const handleOpenNotification = (e) => SetNotificationEl(e.currentTarget);
  const openNotification = Boolean(notificationEl);
  const t = useTranslation();
  const { isMobile } = useScreenDetector();
  const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',
    'Ganymede',
    'Hangouts Call',
    'Luna',
    'Oberon',
    'Phobos',
    'Pyxis',
    'Sedna',
    'Titania',
    'Triton',
    'Umbriel',
  ];

  return null;

  return (
    <>
      <Tooltip
        sx={{ mx: '10px' }}
        title={`You have ${badgeContent} ${badgeContent > 1 ? 'notifications' : 'notification'}`}
      >
        <Badge badgeContent={badgeContent} color="primary">
          <IconButton size="medium" color="inherit" onClick={handleOpenNotification}>
            <NotificationsOutlinedIcon />
          </IconButton>
        </Badge>
      </Tooltip>
      <Menu
        sx={{
          '& .MuiPaper-root': {
            height: '70vh !important',
            overflowY: 'scroll',
            boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)',
          },
        }}
        anchorEl={notificationEl}
        //id={menuId}
        open={openNotification}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleCloseNotification}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
              mt: 0,
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            sx={{
              minHeight: 0,
              minWidth: 350,
              borderBottom:
                index === options.length - 1 && options.length < 5 ? 'none' : '1px solid #EBEBEB',
            }}
          >
            {option}
          </MenuItem>
        ))}
        {options.length > 5 && (
          <MenuItem
            sx={{ minHeight: 0, minWidth: 350, color: 'blue', textDecoration: 'underline' }}
          >
            see more
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
export default NotificationBell;
