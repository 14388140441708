import React from 'react';
import PropTypes from 'prop-types';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Checkbox } from '@mui/material';

const RenderAutoCompleteOptions = ({ showCheckbox, option, inputValue, selected, ...props }) => {
  const matches = match(option?.label, inputValue, { insideWords: true });
  const parts = parse(option?.label, matches);
  return (
    <li {...props} style={{ minHeight: 'unset' }}>
      {showCheckbox ? <Checkbox style={{ marginRight: 8 }} checked={selected} /> : false}
      {parts.map((part, index) => (
        <span
          // eslint-disable-next-line
          key={index}
          style={{
            fontWeight: part?.highlight ? 700 : 400,
          }}
        >
          {part?.text}
        </span>
      ))}
    </li>
  );
};

RenderAutoCompleteOptions.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string,
  }),
  inputValue: PropTypes.string,
  showCheckbox: PropTypes.bool,
  selected: PropTypes.bool,
};

RenderAutoCompleteOptions.defaultProps = {
  option: { label: '' },
  inputValue: '',
  showCheckbox: false,
  selected: false,
};

export default RenderAutoCompleteOptions;
