/* eslint-disable */
import axios from 'axios';
import tokenServices from '../../services/utils/tokenServices';
import { RefreshTokenURL } from '../../services/microServices';

export const AxiosInstance = (baseURL) => {
  const instance = axios.create({ baseURL });
  instance.interceptors.request.use(
    (config) => {
      const token = tokenServices.getAccessToken();
      if (token) config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => Promise.reject(error),
  );

  instance.interceptors.response.use(
    (res) => ({ data: res.data, status: res.status }),
    async (err) => {
      const originalConfig = err.config;
      if (
        !['/register', '/verify', '/verification', '/login'].includes(originalConfig?.url) &&
        err?.response
      ) {
        // Access Token was expired
        if (
          err.response.status === 401 &&
          !originalConfig._retry &&
          tokenServices.getRefreshToken
        ) {
          originalConfig._retry = true;
          await axios
            .post(RefreshTokenURL, { refreshToken: tokenServices.getRefreshToken() })
            .then((res) => {
              if (res?.status === 200) {
                tokenServices.updateAccessToken(res?.data?.data?.accessToken);
              }
            })
            .catch((error) => Promise.reject(error));
          return instance(originalConfig);
        }
      }
      return Promise.reject(err);
    },
  );

  return instance;
};

const api = (baseUrl) => {
  const instance = AxiosInstance(baseUrl);
  return async ({
    url,
    method,
    data,
    params,
    cancelToken = '',
    headers = { 'Content-Type': 'application/json' },
  }) => {
    try {
      const result = await instance(
        { url, method, params, data },
        { cancelToken, headers: { ...headers } },
      );
      return { data: result.data, status: result.status };
    } catch (error) {
      return {
        error: {
          status: error?.response?.status,
          data: error?.response?.data?.message || error?.message,
        },
      };
    }
  };
};

export default api;
