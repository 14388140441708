export const isExpired = (expTime) => (expTime ? Math.floor(Date.now() / 1000) > expTime : true);

export const getChangedValues = (values, initialValues) =>
  Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = JSON.stringify(initialValues[key]) !== JSON.stringify(value);
    if (hasChanged) acc[key] = value;
    return acc;
  }, {});

export const throttle = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      return;
    }
    timeoutId = setTimeout(() => {
      fn(...args);
      timeoutId = null;
    }, delay);
  };
};
export const delay = (delay) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

export const hasOwnProperty = (object, property) =>
  Object.prototype.hasOwnProperty.call(object, property);

export const generateFilterOptions = (data) => {
  const departmentOptions = {};
  const vehicleOptions = {};
  const startStationOptions = {};
  const endStationOptions = {};
  const issueTypeOptions = {};
  const alaramOptions = {};

  data.forEach(({ department, vehicleName, start, end, issue, alarm }) => {
    if (department) departmentOptions[department] = { label: department, value: department };
    if (vehicleName) vehicleOptions[vehicleName] = { label: vehicleName, value: vehicleName };
    if (start) startStationOptions[start] = { label: start, value: start };
    if (end) endStationOptions[end] = { label: end, value: end };
    if (issue) issueTypeOptions[issue] = { label: issue, value: issue };
    if (alarm) alaramOptions[alarm] = { label: alarm, value: alarm };
  });

  return {
    departmentOptions: Object.values(departmentOptions),
    vehicleOptions: Object.values(vehicleOptions),
    startStationOptions: Object.values(startStationOptions),
    endStationOptions: Object.values(endStationOptions),
    issueTypeOptions: Object.values(issueTypeOptions),
    alaramOptions: Object.values(alaramOptions),
  };
};

export const getQueryParams = () => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const queryParams = {};
  for (const [key, value] of searchParams.entries()) {
    queryParams[key] = decodeURIComponent(value);
  }
  return queryParams;
};

export function setQueryParam(key, value) {
  const searchParams = new URLSearchParams(window.location.search);

  if (value === '') {
    searchParams.delete(key);
  } else {
    searchParams.set(key, value);
  }

  const newSearch = searchParams.toString();

  const newUrl = newSearch ? `${window.location.pathname}?${newSearch}` : window.location.pathname;

  window.history.replaceState(null, '', newUrl);
}
