import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material';
import ErrorBoundary from './ErrorBoundary';
import store from './store';
import TypographyLocalizationProvider from './context/TypographyLocalizationProvider';
import COSThemeProvider from './context/COSThemeProvider';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <TypographyLocalizationProvider>
        <StyledEngineProvider injectFirst>
          <COSThemeProvider>
            <App />
          </COSThemeProvider>
        </StyledEngineProvider>
      </TypographyLocalizationProvider>
    </Provider>
  </ErrorBoundary>,
);
