import React from 'react';
import PropTypes from 'prop-types';

const VehiclesIcon = ({ fill, style }) => (
  <svg
    style={{ ...style }}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M4.775 20.5C4.00417 20.5 3.34896 20.2306 2.80937 19.6919C2.26979 19.1532 2 18.499 2 17.7294C2 16.9598 2.26979 16.3042 2.80937 15.7625C3.34896 15.2208 4.00417 14.95 4.775 14.95C5.40833 14.95 5.98333 15.125 6.5 15.475C7.01667 15.825 7.34167 16.3 7.475 16.9H13.475C13.6083 15.7 14.0833 14.6625 14.9 13.7875C15.7167 12.9125 16.725 12.3667 17.925 12.15L16.35 5H12.4V3.5H16.025C16.475 3.5 16.8792 3.63333 17.2375 3.9C17.5958 4.16667 17.825 4.51667 17.925 4.95L19.775 13.35H19.225C18.0288 13.35 17.0047 13.7784 16.1528 14.6351C15.3009 15.4919 14.875 16.5219 14.875 17.725V18.4H7.475C7.34167 19.0333 7.01667 19.5417 6.5 19.925C5.98333 20.3083 5.40833 20.5 4.775 20.5ZM4.775 19C5.125 19 5.425 18.875 5.675 18.625C5.925 18.375 6.05 18.075 6.05 17.725C6.05 17.375 5.925 17.075 5.675 16.825C5.425 16.575 5.125 16.45 4.775 16.45C4.425 16.45 4.125 16.575 3.875 16.825C3.625 17.075 3.5 17.375 3.5 17.725C3.5 18.075 3.625 18.375 3.875 18.625C4.125 18.875 4.425 19 4.775 19ZM19.2294 20.5C18.4598 20.5 17.8042 20.2306 17.2625 19.6919C16.7208 19.1532 16.45 18.499 16.45 17.7294C16.45 16.9598 16.7194 16.3042 17.2581 15.7625C17.7968 15.2208 18.451 14.95 19.2206 14.95C19.9902 14.95 20.6458 15.2194 21.1875 15.7581C21.7292 16.2968 22 16.951 22 17.7206C22 18.4902 21.7306 19.1458 21.1919 19.6875C20.6532 20.2292 19.999 20.5 19.2294 20.5ZM19.225 19C19.575 19 19.875 18.875 20.125 18.625C20.375 18.375 20.5 18.075 20.5 17.725C20.5 17.375 20.375 17.075 20.125 16.825C19.875 16.575 19.575 16.45 19.225 16.45C18.875 16.45 18.575 16.575 18.325 16.825C18.075 17.075 17.95 17.375 17.95 17.725C17.95 18.075 18.075 18.375 18.325 18.625C18.575 18.875 18.875 19 19.225 19ZM10.5 12.925L4.775 10H8.525V8.025L14.25 10.975H10.5V12.925Z"
      fill={fill}
    />
  </svg>
);

VehiclesIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.any,
};

VehiclesIcon.defaultProps = {
  fill: '#575757',
  style: {},
};

export default VehiclesIcon;
