import { typography } from './language';

const speedConverter = (unit) => {
  switch (unit) {
    case 'kmh':
      return 1.852;
    case 'mph':
      return 1.15078;
    case 'kn':
    default:
      return 1;
  }
};

export const speedUnitString = (unit) => {
  switch (unit) {
    case 'kmh':
      return typography('sharedKmh');
    case 'mph':
      return typography('sharedMph');
    case 'kn':
    default:
      return typography('sharedKn');
  }
};

export const speedFromKnots = (value, unit) => value * speedConverter(unit);

export const speedToKnots = (value, unit) => value / speedConverter(unit);

const distanceConverter = (unit) => {
  switch (unit) {
    case 'mi':
      return 0.000621371;
    case 'nmi':
      return 0.000539957;
    case 'km':
    default:
      return 0.001;
  }
};

export const distanceUnitString = (unit) => {
  switch (unit) {
    case 'mi':
      return typography('sharedMi');
    case 'nmi':
      return typography('sharedNmi');
    case 'km':
    default:
      return typography('sharedKm');
  }
};

export const distanceFromMeters = (value, unit) => value * distanceConverter(unit);

export const distanceToMeters = (value, unit) => value / distanceConverter(unit);

const altitudeConverter = (unit) => {
  switch (unit) {
    case 'ft':
      return 3.28084;
    case 'm':
    default:
      return 1;
  }
};

export const altitudeUnitString = (unit) => {
  switch (unit) {
    case 'ft':
      return typography('sharedFeet');
    case 'm':
    default:
      return typography('sharedMeters');
  }
};

export const altitudeFromMeters = (value, unit) => value * altitudeConverter(unit);

export const altitudeToMeters = (value, unit) => value / altitudeConverter(unit);

const volumeConverter = (unit) => {
  switch (unit) {
    case 'impGal':
      return 4.546;
    case 'usGal':
      return 3.785;
    case 'ltr':
    default:
      return 1;
  }
};

export const volumeUnitString = (unit) => {
  switch (unit) {
    case 'impGal':
      return typography('sharedGallonAbbreviation');
    case 'usGal':
      return typography('sharedGallonAbbreviation');
    case 'ltr':
    default:
      return typography('sharedLiterAbbreviation');
  }
};

export const volumeFromLiters = (value, unit) => value / volumeConverter(unit);

export const volumeToLiters = (value, unit) => value * volumeConverter(unit);

export const epochTimeDifference = (startTime, endTime) =>
  (new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000;

export const getEpochTimeInSeconds = (date) => Math.floor(date.getTime() / 1000);
