/* eslint-disable */

import { getPersistedState, removePersistedState, setPersistedState } from '../../utils';

class TokenService {
  #_token = getPersistedState('token') || {
    refreshToken: '',
    accessToken: '',
    trackToken: '',
  };

  setToken(token) {
    this.#_token = token;
    setPersistedState('token', token);
  }

  getToken() {
    return this.#_token;
  }

  getAccessToken() {
    return this.#_token.accessToken;
  }

  getRefreshToken() {
    return this.#_token.refreshToken;
  }

  getTrackToken() {
    return this.#_token.trackToken;
  }

  deleteToken() {
    this.#_token = {};
    removePersistedState('token');
  }

  updateAccessToken(accessToken) {
    this.#_token = {
      accessToken,
      refreshToken: this.#_token.refreshToken,
      trackToken: this.#_token.trackToken,
    };
    setPersistedState('token', this.#_token);
  }
}

export default new TokenService();
