import apiPages from './apiPages';

export const FE_PAGES = {
  DASHBOARD: '239f07d9',
  TRACK: 'e88ad140',
  VEHICLES: 'a0bf4da1',
  DEPLOYED_VEHICLES: '788aa543',
  BATTERIES: 'ac80820b',
  HUBS: '8966fc11',
  TENANTS: '561df8f4',
  RIDERS: 'fdaa952a',
  SERVICE_TICKETS: '20898dec',
  REPORTS: 'e3d89ca2',
  ACCOUNT: '232b3e43',
  SECURITY: '73722bf5',
  TEAMS: '452ff60a',
  EXECUTIVES: '49a4d31b',
  APPROVALS: '4f1af0d8',
  RENTAL_FOLLOW_UPS: 'a6a5b29f',
  ALLOCATED_FOR_RECOVERY: 'c2fc4d8c',
  CREDIT_LOGS: '1623a0cf',

  // Rider more details pages
  RIDER_PROFILE: 'd1307976',
  RIDER_PAYMENT_LOG: '70bffa3f',
  RIDER_RENTAL_HISTORY: '1e43bf22',
  RIDER_SERVICE_HISTORY: 'fcfb4c3a',
  RIDER_DOCUMENTS: '3cf38096',
  RIDER_WALLET: '2499b0eb',

  // Rental Events
  RENTAL_EVENTS: '369946ef',
};

export const FE_PAGE_ACCESS = {
  [FE_PAGES.DASHBOARD]: [
    apiPages.SUPER_ADMIN_DASHBOARD,
    apiPages.TENANT_ADMIN_DASHBOARD,
    apiPages.HUB_ADMIN_DASHBOARD,
  ],

  [FE_PAGES.TRACK]: [apiPages.SUPER_ADMIN_TRACKING, apiPages.TENANT_ADMIN_TRACKING],

  [FE_PAGES.VEHICLES]: [
    apiPages.SUPER_ADMIN_VEHICLES,
    apiPages.TENANT_ADMIN_VEHICLES,
    apiPages.HUB_ADMIN_VEHICLES,
  ],

  [FE_PAGES.BATTERIES]: [apiPages.SUPER_ADMIN_BATTERIES, apiPages.HUB_ADMIN_BATTERIES],

  [FE_PAGES.RIDERS]: [apiPages.SUPER_ADMIN_RIDERS, apiPages.TENANT_ADMIN_RIDERS],

  [FE_PAGES.SERVICE_TICKETS]: [
    apiPages.SUPER_ADMIN_SERVICE_TICKETS,
    apiPages.TENANT_ADMIN_SERVICE_TICKETS,
    apiPages.HUB_ADMIN_SERVICE_TICKETS,
  ],

  // Only for Super admin
  [FE_PAGES.TENANTS]: [apiPages.SUPER_ADMIN_TENANTS],
  [FE_PAGES.HUBS]: [apiPages.SUPER_ADMIN_HUBS],
  [FE_PAGES.DEPLOYED_VEHICLES]: [apiPages.DEPLOYED_VEHICLES],

  // Only for Hub Admin
  [FE_PAGES.EXECUTIVES]: [apiPages.HUB_ADMIN_EXECUTIVES],

  // Common for Super-admin and tenant-admin
  [FE_PAGES.TEAMS]: [
    apiPages.SUPER_ADMIN_TEAMS,
    apiPages.TENANT_ADMIN_TEAMS,
    apiPages.HUB_ADMIN_TEAMS,
  ],

  // Others
  [FE_PAGES.REPORTS]: [apiPages.SUPER_ADMIN_REPORTS],

  [FE_PAGES.APPROVALS]: [apiPages.HUB_ADMIN_APPROVALS],

  // C-S Team
  [FE_PAGES.RENTAL_FOLLOW_UPS]: [apiPages.SUPER_ADMIN_RENTAL_FOLLOW_UPS],

  // Recovery
  [FE_PAGES.ALLOCATED_FOR_RECOVERY]: [apiPages.SUPER_ADMIN_ALLOCATED_FOR_RECOVERY],

  // Accounts
  [FE_PAGES.CREDIT_LOGS]: [apiPages.SUPER_ADMIN_CREDIT_LOGS],

  // Riders More Details
  [FE_PAGES.RIDER_DOCUMENTS]: [apiPages.SUPER_ADMIN_RIDERS], // For Now only super admin and his team who has access to riders page
  [FE_PAGES.RIDER_PAYMENT_LOG]: [apiPages.SUPER_ADMIN_RIDERS], // For Now only super admin and his team who has access to riders page
  [FE_PAGES.RIDER_PROFILE]: [apiPages.SUPER_ADMIN_RIDERS], // For Now only super admin and his team who has access to riders page
  [FE_PAGES.RIDER_RENTAL_HISTORY]: [apiPages.SUPER_ADMIN_RIDERS], // For Now only super admin and his team who has access to riders page
  [FE_PAGES.RIDER_SERVICE_HISTORY]: [apiPages.SUPER_ADMIN_RIDERS], // For Now only super admin and his team who has access to riders page
  [FE_PAGES.RIDER_WALLET]: [apiPages.SUPER_ADMIN_RIDERS], // For Now only super admin and his team who has access to riders page

  [FE_PAGES.RENTAL_EVENTS]: [apiPages.SUPER_ADMIN_RENTAL_EVENTS],

  // Common for all
  [FE_PAGES.ACCOUNT]: [apiPages.ACCOUNT],
  [FE_PAGES.SECURITY]: [apiPages.SECURITY],
};
