import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { errorsActions } from '../store/errors';

export const useCatch = (method) => {
  const dispatch = useDispatch();
  return (...parameters) => {
    method(...parameters).catch((error) => {
      let data = '';
      if (typeof error === 'string') data = error;
      if (error.message) data = error.message;
      dispatch(errorsActions.push({ status: 400, data }));
    });
  };
};

export const useCatchCallback = (method, deps) => {
  return useCallback(useCatch(method), deps);
};
