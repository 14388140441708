import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import {
  Page404,
  Page500,
  LoginPage,
  RegisterPage,
  DashboardPage,
  VerifyPage,
  TrackPage,
  RidersPage,
  ServiceTicketsPage,
  AccountPage,
  SecurityPage,
  TripsPage,
  RoutePage,
  ReplayPage,
  VehicleAnalyticsPage,
  ResetPassword,
  WelcomePage,
  AllTripsPage,
  AlertsPage,
  ReportsPage,
  VehiclePage,
} from './pages';
import { LoginLayout, ReportsLayout, SettingsLayout } from './layouts';

import store from './store';
import AppLayout from './layouts/App/AppLayout';
import { getPersistedState } from './utils';
import { userSlice } from './services/slices/user/userSlice';

const caseSensitive = true;
const root = true;
const index = true;
const showParams = true;
const subRoute = true;

export default () =>
  createBrowserRouter([
    {
      path: '*',
      element: <Page404 />,
    },
    {
      element: <LoginLayout />,
      errorElement: <Page500 />,
      children: [
        { path: '/login', caseSensitive, element: <LoginPage /> },
        { path: '/register', caseSensitive, element: <RegisterPage /> },
        { path: '/reset-password/:emailId', caseSensitive, element: <ResetPassword /> },
        {
          path: '/verification/:emailId/:token',
          caseSensitive,
          element: <VerifyPage verification />,
        },
      ],
    },
    {
      path: '/',
      handle: { root },
      element: <AppLayout />,
      loader: () => {
        if (getPersistedState('token')) {
          store.dispatch(
            userSlice.endpoints.getUserDetails.initiate(null, { forceRefetch: false }),
          );
        }
        return '';
      },
      errorElement: <Page500 />,
      children: [
        {
          index,
          caseSensitive,
          handle: { root },
          element: <WelcomePage />,
        },
        {
          path: 'dashboard',
          caseSensitive,
          handle: { name: 'Dashboard Overview' },
          errorElement: <Page500 />,
          element: <DashboardPage />,
        },

        {
          path: 'track',
          caseSensitive,
          handle: { name: 'Track', index },
          errorElement: <Page500 />,
          element: <TrackPage />,
        },
        {
          path: 'vehicles',
          caseSensitive,
          handle: { name: 'All Vehicles', root },
          errorElement: <Page500 />,
          element: <VehiclePage />,
        },
        {
          path: 'trips/:deviceId',
          caseSensitive,
          handle: { name: 'Trips', index, showParams, crumbs: ['deviceId'] },
          errorElement: <Page500 />,
          element: <TripsPage />,
        },
        {
          path: 'vehicle-analytics/:deviceId',
          caseSensitive,
          handle: { name: 'Vehicle Analytics', index, showParams, crumbs: ['deviceId'] },
          errorElement: <Page500 />,
          element: <VehicleAnalyticsPage />,
        },
        {
          path: 'riders',
          caseSensitive,
          handle: { name: 'Riders' },
          errorElement: <Page500 />,
          children: [
            {
              index,
              caseSensitive,
              handle: { name: 'Riders' },
              element: <RidersPage />,
            },
          ],
        },
        {
          path: 'serviceTickets',
          caseSensitive,
          errorElement: <Page500 />,
          handle: { name: 'Service tickets' },
          element: <ServiceTicketsPage />,
        },
        {
          path: 'insights',
          caseSensitive,
          element: <ReportsLayout />,
          handle: { name: 'Insights & Analytics', root },
          errorElement: <Page500 />,
          children: [
            {
              path: 'ride',
              handle: { name: 'Trip Analytics', index, root },
              caseSensitive,
              children: [
                {
                  index,
                  caseSensitive,
                  handle: { name: 'Ride Analytics' },
                  element: <TripsPage />,
                },
                {
                  path: 'allrides/:deviceId/:routeId',
                  handle: { name: 'Rides', index, showParams, crumbs: ['deviceId'] },
                  element: <AllTripsPage />,
                  caseSensitive,
                },
              ],
            },
            {
              path: 'replay',
              handle: { name: 'Replay', showParams: false },
              element: <ReplayPage />,
              caseSensitive,
            },
            {
              path: 'reports',
              handle: { name: 'Reports', showParams: false },
              element: <ReportsPage />,
              caseSensitive,
            },
            {
              path: 'alerts',
              handle: { name: 'Alerts', showParams: false },
              element: <AlertsPage />,
              caseSensitive,
            },
          ],
        },
        {
          path: 'settings',
          caseSensitive,
          element: <SettingsLayout />,
          handle: { name: '', root },
          errorElement: <Page500 />,
          children: [
            {
              path: 'account',
              handle: { name: 'Account', showParams: false },
              element: <AccountPage />,
              caseSensitive,
            },
            {
              path: 'security',
              handle: { name: 'Security' },
              element: <SecurityPage />,
              caseSensitive,
            },
          ],
        },
      ],
    },
  ]);
