import React from 'react';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

const Fallback = () => {
  return (
    <Box sx={styles.container}>
      <CircularProgress />
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Adjust height as needed
  },
};

export default Fallback;
