import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import AppHeader from './components/AppHeader';
import AppSideBar from './components/AppSideBar';
import ErrorHandler from '../../components/ErrorHandler';
import { getPersistedState } from '../../utils';
import Fallback from '../../components/ProgressBar/CircularProgress';

const AuthHandler = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const loggedId = getPersistedState('token');
    if (!loggedId) {
      navigate('/login'); // Just to make sure all the sub pages has auth --> Checkig only for
      return;
    }
  }, []);

  return null;
};

export default () => (
  <>
    <Box display="flex">
      <AppHeader />
      <AppSideBar />
      <Box
        component="main"
        sx={{
          width: '100%',
          pt: (t) => t.dimentions.headerheight,
          pl: (t) => ({ md: t.dimentions.sidebarWidth, xs: 0 }),
        }}
      >
        <React.Suspense fallback={<Fallback />}>
          <Outlet />
        </React.Suspense>
      </Box>
    </Box>
    <AuthHandler />
    <ErrorHandler />
  </>
);
