import dayjs from 'dayjs';

export const baseDateFilters = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  LAST_WEEK: 'Last week',
  LAST_MONTH: 'Last month',
  CUSTOM: 'Custom',
};

export const baseDateFilterOptions = Object.entries(baseDateFilters).map(([_, label]) => ({
  label,
  value: label,
}));

export const getDateFilterRanges = ([startDate, endDate]) => {
  // dayjs.extend(isSameOrAfter);
  let selectedType = baseDateFilters.CUSTOM;

  const today = dayjs().endOf('day');
  const yesterday = today.subtract(1, 'day');

  const timeDifferenceInDays = dayjs(startDate).diff(dayjs(today), 'day');
  const timeDifferenceInMonths = dayjs(startDate).diff(dayjs(today), 'month');

  // Check for Today
  if (dayjs(startDate).isSame(today, 'day') && dayjs(endDate).isSame(today, 'day')) {
    selectedType = baseDateFilters.TODAY;
  }
  // Check for yesterday
  else if (dayjs(startDate).isSame(yesterday, 'day') && dayjs(endDate).isSame(yesterday, 'day')) {
    selectedType = baseDateFilters.YESTERDAY;
  }
  //   // Check for last 7 days
  //   else if (dayjs(endDate).isSameOrAfter(today.subtract(6, 'day'), 'day')) {
  //     selectedType = baseDateFilters.LAST7DAYS;
  //   }
  //   // Check for last 30 days
  //   else if (dayjs(endDate).isSameOrAfter(today.subtract(29, 'day'), 'day')) {
  //     selectedType = baseDateFilters.LAST30DAYS;
  //   }

  // Check for last week
  else if (timeDifferenceInDays === -7 && dayjs(endDate).isSame(today, 'day')) {
    selectedType = baseDateFilters.LAST_WEEK;
  }
  // Check for last month
  else if (
    timeDifferenceInMonths === -1 &&
    timeDifferenceInDays >= -31 &&
    dayjs(endDate).isSame(today, 'month')
  ) {
    selectedType = baseDateFilters.LAST_MONTH;
  }

  return selectedType;
};

export const getDateRangeFromFilterOptions = (optionSelected) => {
  const now = dayjs();

  let startDate;
  let endDate = now.endOf('date'); // Current time

  if (!optionSelected) return [];

  switch (optionSelected) {
    /// Check for time related bugs, between calender and optioin is resolved but need to check as per other senario's might be when
    case 'Today':
      startDate = now.startOf('day'); // Today's 12:00:00 am
      break;
    case 'Yesterday':
      startDate = now.subtract(1, 'day').startOf('day'); // Yesterday's 12:00:00 am
      endDate = now.subtract(1, 'day').endOf('day'); // Yesterday's 11:59:59 pm
      break;
    case 'Last week':
    case 'Last 7 days':
      startDate = now.subtract(1, 'week').startOf('date'); // 1 week ago from today's 12:00:00 am
      break;
    case 'Last month':
    case 'Last 30 days':
      startDate = now.subtract(1, 'month'); // 1 month ago from today's 12:00:00 am
      break;
    case 'Last 6 months':
      startDate = now.subtract(6, 'month'); // 6 months ago from today's 12:00:00 am
      break;
    case 'Last 12 months':
      startDate = now.subtract(1, 'year'); // 1 year ago from today's 12:00:00 am
      break;
    default:
      // Check for Time related bugs
      // Default to Today if the selected option is not recognized
      startDate = now; // Today's 12:00:00 am
      break;
  }
  return [startDate.toDate(), endDate.toDate()];
};
