const { REACT_APP_URI, REACT_APP_API_VERSION, REACT_APP_WEB_SOCKET } = process.env;

const {
  location: { protocol },
} = window;

export const WebSocketMicroService = `https://${REACT_APP_WEB_SOCKET}/socket`; // `/socket` is for production

// one micro service for   all msil project others will be delete after integrating other pages

export const MsilMicroService = `https://${REACT_APP_URI}/api/${REACT_APP_API_VERSION}/`;
export const RefreshTokenURL = `https://${REACT_APP_URI}/api/${REACT_APP_API_VERSION}/user/refresh-token`;
