import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import RenderAutoCompleteOptions from './helpers/RenderAutoCompleteOptions';
import { deviceStatus, statusMap } from '../../data/constants/deviceStatus';
import { RadioIcon, RadioCheckedIcon } from './helpers/RadioIcon';

export default {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      variant: 'contained',
      size: 'medium',
    },
    styleOverrides: {
      root: { boxShadow: 'unset' },
    },
    variants: [
      {
        props: { variant: 'confirm' },
        style: {
          color: '#fff',
          fontWeight: 500,
          backgroundColor: '#D9003C',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.09))',
          '&:hover': { backgroundColor: '#D9003C' },
          '&:focus': { backgroundColor: '#D9003C' },
        },
      },
      {
        props: { variant: 'poweron' },
        style: {
          color: '#12B76A',
          fontWeight: 500,
          padding: '5px 10px',
          minWidth: '140px',
          //boxShadow: `1px -1px 142px 0px #00000000`,
          border: '1px solid #12B76A',
          borderRadius: '68px',
          boxShadow: '0px 8px 12px 0px rgba(0, 0, 0, 0.12)',
        },
      },
      {
        props: { variant: 'poweroff' },
        style: {
          color: '#F04438',
          fontWeight: 500,
          padding: '5px 10px',
          minWidth: '140px',
          //boxShadow: `1px -1px 142px 0px #00000000`,
          border: '1px solid #F04438',
          borderRadius: '68px',
          boxShadow: '0px 8px 12px 0px rgba(0, 0, 0, 0.12)',
        },
      },
      {
        props: { variant: 'loaddata' },
        style: {
          color: '#12BBC4',
          fontWeight: 500,
          padding: '6px 16px',
          minWidth: '120px',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
          border: '1px solid #12BBC4',
          borderRadius: '8px',
        },
      },
      {
        props: { variant: 'loaddata-grey' },
        style: {
          color: '#757575',
          fontWeight: 500,
          padding: '6px 16px',
          minWidth: '120px',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
          border: '1px solid #D9D9D9',
          borderRadius: '8px',
        },
      },
      {
        props: { variant: 'grey' },
        style: {
          color: '#757575',
          fontWeight: 500,
          backgroundColor: '#FBFBFB',
          borderRadius: '6px',
          border: '1px solid #D9D9D9',
          '&:hover': { backgroundColor: '#FBFBFB' },
          '&:focus': { backgroundColor: '#FBFBFB' },
        },
      },
      {
        props: { variant: 'breadcrumb' },
        style: {
          color: '#575757',
          padding: 0,
          fontSize: '16px',
          fontWeight: 500,
          textDecoration: 'underline',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.09))',
          '&:hover': { textDecoration: 'underline', backgroundColor: 'transparent' },
          '&:focus': { textDecoration: 'underline', backgroundColor: 'transparent' },
        },
      },
      {
        props: { variant: 'neutral' },
        style: {
          color: '#575757',
          fontWeight: 400,
          textDecoration: 'underline',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.09))',
          '&:hover': { textDecoration: 'underline', backgroundColor: 'transparent' },
          '&:focus': { textDecoration: 'underline', backgroundColor: 'transparent' },
        },
      },
      {
        props: { variant: 'help' },
        style: {
          color: '#757575',
          textDecoration: 'underline',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.09))',
          '&:hover': { textDecoration: 'underline', backgroundColor: 'transparent' },
          '&:focus': { textDecoration: 'underline', backgroundColor: 'transparent' },
        },
      },
      {
        props: { variant: 'primary' },
        style: {
          color: '#3DC4C4',
          fontWeight: 400,
          padding: 0,
          textDecoration: 'underline',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.09))',
          '&:hover': { textDecoration: 'underline', backgroundColor: 'transparent' },
          '&:focus': { textDecoration: 'underline', backgroundColor: 'transparent' },
        },
      },
      {
        props: { variant: 'error' },
        style: {
          color: '#d32f2f',
          fontWeight: 400,
          padding: 0,
          textDecoration: 'underline',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.09))',
          '&:hover': { textDecoration: 'underline', backgroundColor: 'transparent' },
          '&:focus': { textDecoration: 'underline', backgroundColor: 'transparent' },
        },
      },
      {
        props: { variant: 'info' },
        style: {
          fontWeight: 400,
          color: '#1F91D0',
          textDecoration: 'underline',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.09))',
          '&:hover': { textDecoration: 'underline', backgroundColor: 'transparent' },
          '&:focus': { textDecoration: 'underline', backgroundColor: 'transparent' },
        },
      },
      {
        props: { variant: 'outlined-secondary' },
        style: {
          fontWeight: 400,
          color: '#757575',
          border: '1px solid #EBEBEB',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.09))',
          '&:hover': { backgroundColor: 'transparent' },
          '&:focus': { backgroundColor: 'transparent' },
        },
      },
    ],
  },
  MuiAlert: {
    defaultProps: {},
    styleOverrides: {
      root: {
        '& .MuiAlert-action': { alignItems: 'center' },
      },
    },
  },
  MuiAppBar: {
    defaultProps: {},
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderBottom: '1px solid #EBEBEB',
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      disableClearable: true,
      limitTags: 3,
      ChipProps: { deleteIcon: <ClearSharpIcon fontSize="small" /> },
      isOptionEqualToValue: (option, value) => option?.value === value?.value,
      popupIcon: <ExpandMoreIcon />,
      renderOption: (props, option, { inputValue, selected }) => (
        <RenderAutoCompleteOptions
          option={option}
          selected={selected}
          inputValue={inputValue}
          {...props}
        />
      ),
    },
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          padding: '6px',
        },
        input: {
          padding: '1px !important',
        },
      },
    },
  },
  MuiAvatar: { styleOverrides: { root: {} } },
  MuiBadge: {
    variants: [
      {
        props: { variant: 'tickets-badge' },
        style: {
          width: '100%',
          '& .MuiBadge-tickets-badge': {
            background: '#d9d9d9',
            color: '#575757',
          },
        },
      },
    ],
  },
  MuiBreadcrumbs: {},
  MuiButtonGroup: {
    defaultProps: {
      variant: 'outlined',
      disableRipple: true,
    },
  },
  MuiCard: {},
  MuiCardActionArea: {},
  MuiCardActions: {},
  MuiCardHeader: {},
  MuiCardMedia: {},
  MuiCheckbox: {
    defaultProps: {
      size: 'small',
      disableRipple: true,
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" fill="white" />
          <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#D9D9D9" />
        </svg>
      ),
      checkedIcon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="#3DC4C4" />
          <path
            d="M14.6668 6.5L8.25016 12.9167L5.3335 10"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" stroke="#D9D9D9" />
        </svg>
      ),
    },
    styleOverrides: {
      root: {
        padding: 0,
        '&.Mui-disabled': {
          // borderRadius: '3.393px',
          // border: '0.848px solid rgba(117, 117, 117, 0.47)',
          // background: '#A5A5A5',
        },
      },
    },
  },
  MuiChip: {
    defaultProps: {
      variant: 'filled',
      deleteIcon: <ClearSharpIcon />,
    },
    styleOverrides: {
      root: {
        borderRadius: '4px',
        padding: '1px 8px',
        color: '#000',
        '& .MuiChip-label': {
          paddingLeft: 0,
          color: '#000',
        },
        '& .MuiChip-deleteIcon': {
          width: '17px',
          margin: '0',
          color: '#000',
          '&:hover': {
            color: 'inherit',
          },
        },
        '&.Mui-disabled': {
          opacity: 1,
          background: '#fff',
          color: '#575757',
          '& .MuiChip-deleteIcon': {
            display: 'none',
          },
        },
      },
    },
    variants: [
      {
        props: { variant: 'purple' },
        style: {
          fontWeight: 500,
          backgroundColor: '#F8E6F8',
          padding: '4px 16px',
          borderRadius: '100px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: '#800080',
          },
        },
      },
      {
        props: { variant: 'voilet' },
        style: {
          fontWeight: 500,
          backgroundColor: '#F8F9FC',
          padding: '4px 16px',
          borderRadius: '100px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: '#363F72',
          },
        },
      },
      {
        props: { variant: 'success' },
        style: {
          fontWeight: 500,
          backgroundColor: '#ECFDF3',
          padding: '4px 16px',
          borderRadius: '100px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: '#027A48',
          },
        },
      },
      {
        props: { variant: 'info' },
        style: {
          fontWeight: 500,
          backgroundColor: '#E0E9FE',
          padding: '4px 16px',
          borderRadius: '100px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: '#0E62CF',
          },
        },
      },
      {
        props: { variant: 'error' },
        style: {
          fontWeight: 500,
          backgroundColor: '#FFDEDE',
          padding: '4px 16px',
          borderRadius: '100px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: '#B42318',
          },
        },
      },
      {
        props: { variant: 'warning' },
        style: {
          fontWeight: 500,
          backgroundColor: '#FDECCA',
          padding: '4px 16px',
          borderRadius: '100px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: '#CC8400',
          },
        },
      },
      {
        props: { variant: 'neutral' },
        style: {
          fontWeight: 500,
          backgroundColor: '#EBEBEB',
          padding: '4px 16px',
          borderRadius: '100px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: '#101828',
          },
        },
      },
      {
        props: { variant: 'pending' },
        style: {
          fontWeight: 500,
          backgroundColor: '#F8F9FC',
          padding: '4px 16px',
          borderRadius: '100px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: '#4E5BA6',
          },
        },
      },
      {
        props: { variant: 'inProgress' },
        style: {
          fontWeight: 500,
          backgroundColor: '#E0E9FE',
          padding: '4px 16px',
          borderRadius: '100px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: '#0E62CF',
          },
        },
      },
      {
        props: { variant: 'device-running' },
        style: {
          fontWeight: 400,
          fontSize: '13px',
          background: 'transparent',
          padding: '4px 16px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: statusMap[deviceStatus.ACTIVE].color,
          },
        },
      },
      {
        props: { variant: 'device-idle' },
        style: {
          fontWeight: 400,
          fontSize: '13px',
          background: 'transparent',
          padding: '4px 16px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: statusMap[deviceStatus.IDLE].color,
          },
        },
      },
      {
        props: { variant: 'device-inactive' },
        style: {
          fontWeight: 400,
          fontSize: '13px',
          background: 'transparent',
          padding: '4px 16px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: statusMap[deviceStatus.INACTIVE].color,
          },
        },
      },
      {
        props: { variant: 'device-noData' },
        style: {
          fontWeight: 400,
          fontSize: '13px',
          background: 'transparent',
          padding: '4px 16px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: statusMap[deviceStatus.NODATA].color,
          },
        },
      },
      {
        props: { variant: 'device-stopped' },
        style: {
          fontWeight: 400,
          fontSize: '13px',
          background: 'transparent',
          padding: '4px 16px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: statusMap[deviceStatus.STOPPED].color,
          },
        },
      },
      {
        props: { variant: 'device-discharging' },
        style: {
          fontWeight: 400,
          fontSize: '13px',
          background: 'transparent',
          padding: '4px 16px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: statusMap[deviceStatus.DISCHARGING].color,
          },
        },
      },
      {
        props: { variant: 'device-charging' },
        style: {
          fontWeight: 400,
          fontSize: '13px',
          background: 'transparent',
          padding: '4px 16px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
            color: statusMap[deviceStatus.CHARGING].color,
          },
        },
      },
    ],
  },
  MuiContainer: {},
  MuiDialog: {
    defaultProps: {
      disableEscapeKeyDown: true,
    },
  },
  MuiDialogActions: {},
  MuiDialogContent: {},
  MuiDialogContentText: {},
  MuiDialogTitle: {},
  MuiDivider: {},
  MuiAccordion: {},
  MuiAccordionActions: {},
  MuiAccordionDetails: {},
  MuiAccordionSummary: {},

  MuiFormControl: {},
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        margin: '0',
        gap: '8px',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        margin: 0,
        fontSize: '12px',
      },
    },
  },
  MuiFormLabel: {},
  MuiGrid: {},
  MuiGrid2: {},

  MuiIcon: {
    defaultProps: {
      fontSize: 'small',
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        color: '#575757',
        marginRight: 0,
        padding: 0,
      },
    },
  },
  MuiInput: {},
  MuiInputAdornment: {
    styleOverrides: { root: { padding: { md: '12px', lg: '0' } } },
  },
  MuiInputBase: {
    defaultProps: {
      variant: 'standard',
      autoComplete: 'off',
    },
    styleOverrides: {
      root: {
        // paddingLeft: '10px',
        '&.Mui-disabled': {
          borderRadius: '4px',
          background: '#F1F1F1',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          backgroundColor: '#f1f1f1',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        input: {
          color: '#101828',
          fontWeight: 400,
          lineHeight: '24px',
          fontSize: '12px',
          padding: '8px',
        },
        '&:focus': {
          border: 'none',
        },
        '&:before': {
          border: 'none',
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: '11px',
        fontWeight: 500,
        lineHeight: '26px',
        color: '#575757',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        padding: 0,
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#3DC4C3',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #D9D9D9',
          borderRadius: '8px',
          // boxShadow: '0 1px 2px 0 rgba(16, 24, 40, 0.05)',
        },
        '& textarea': {
          padding: '10px 14px',
          color: 'initial',
        },
      },
    },
  },

  MuiLinearProgress: {},
  MuiList: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {},
    },
  },
  MuiListItemButton: {
    defaultProps: {
      disableRipple: true,
      disableGutters: true,
    },
    styleOverrides: {
      root: {
        '&::focus': {
          backgroundColor: '#EBEBEB',
        },
        '&:hover': {
          backgroundColor: '#EBEBEB',
        },
        '&.Mui-selected': {
          '&:hover': {
            backgroundColor: '#EBEBEB',
          },
          backgroundColor: '#EBEBEB',
        },
      },
    },
  },
  MuiListItemAvatar: {},
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: 'inherit',
        minWidth: '0',
      },
    },
  },
  MuiListItemSecondaryAction: {},
  MuiListItemText: {},
  MuiListSubheader: {},

  MuiMenu: {},
  MuiMenuItem: {
    styleOverrides: {
      root: { minHeight: '34px', '& .MuiListItemIcon-root': { minWidth: '0' } },
    },
  },
  MuiMobileStepper: {},

  MuiStepper: {
    styleOverrides: {
      root: {
        '& .MuiStepIcon-root': {
          '&.Mui-completed': {
            color: '#3DC4C4',
          },
          '&.Mui-active': {
            color: '#b1e7e7',
          },
          color: '#b1e7e7',
          '& .MuiStepIcon-text': {
            color: '#1B2A2A',
            fill: '#1B2A2A',
            fontWeight: 500,
            fontSize: '15px',
            opacity: 1,
          },
        },
      },
    },
  },

  MuiStep: {
    styleOverrides: {
      root: {
        '& .MuiStepLabel-label': {
          color: '#757575',
          '&.Mui-active': {
            color: '#101828',
          },
          '&.Mui-completed': {
            color: '#757575',
          },
        },
      },
    },
  },

  MuiStepConnector: {
    styleOverrides: {
      root: {
        '& .MuiStepConnector-line': {
          borderTopWidth: '1.5px',
          borderColor: '#d9d9d9',
        },
      },
    },
  },

  MuiModal: {},

  MuiPagination: {},
  MuiPaginationItem: {},
  MuiPaper: {
    styleOverrides: {
      root: {
        '&.MuiAutocomplete-paper': {
          border: '1px solid #EBEBEB',
          borderRadius: '8px',
          borderTop: 'none',
          boxShadow: '0 1px 2px 0 rgba(16, 24, 40, 0.05)',
          fontWeight: 400,
          '& .MuiAutocomplete-noOptions': {
            color: '#3DC4C4',
          },
        },
        '&.MuiPaper-outlined ': {
          borderRadius: '8px',
          border: '1px solid #EBEBEB',
        },
      },
    },
    variants: [
      {
        props: { variant: 'status-card' },
        style: {
          borderRadius: '8px',
          border: '1px solid #EBEBEB',
          background: '#fff',
          boxShadow: '0 0 24px 0 rgba(0, 0, 0, 0.08)',
          flexShrink: 0,
        },
      },
      {
        props: { variant: 'info-card' },
        style: {
          borderRadius: '8px',
          border: '1px solid #EBEBEB',
          padding: '8px',
          background: '#f8f8f8',
          flexShrink: 0,
        },
      },
    ],
  },

  MuiPopper: {},
  MuiPopover: {},

  MuiRadio: {
    defaultProps: {
      size: 'small',
      disableRipple: true,
      icon: <RadioIcon />,
      checkedIcon: <RadioCheckedIcon />,
    },
    styleOverrides: {
      root: {
        padding: '0px',
        '& .MuiSvgIcon-root': {
          width: '22.67px',
          height: '22.67px',
          strokeWidth: '0.838942',
          stroke: '#AEAEB2',
        },
      },
    },
  },
  MuiRating: {},
  MuiSelect: {},
  MuiSkeleton: {},

  MuiSnackbar: {},
  MuiSnackbarContent: {},

  MuiStack: {},
  MuiSvgIcon: {},

  MuiTabs: {
    styleOverrides: {
      root: {
        '& .MuiTabs-flexContainer': {
          gap: '16px',
          // gap: { xs: 0, lg: '24px', md: '16px', sm: '8px' },
        },
        '& .MuiTabs-indicator': {
          height: '3.5px',
        },
      },
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
      disableFocusRipple: true,
      disableTouchRipple: true,
    },
    styleOverrides: {
      root: {
        fontSize: '18px',
        fontWeight: 400,
        padding: '0 12px',
        '&.Mui-selected': { color: '#101828', fontWeight: 500 },
      },
    },
  },

  MuiTable: {},
  MuiTableBody: {},
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '12px 8px',
        borderColor: '#EBEBEB',
      },
    },
  },
  MuiTableContainer: {},
  MuiTableFooter: {},
  MuiTableHead: {},
  MuiTablePagination: {},
  MuiTableRow: {},
  MuiTableSortLabel: {},

  MuiTextField: {
    defaultProps: {
      fullWidth: true,
      variant: 'outlined',
    },
    styleOverrides: {},
  },
  MuiToggleButton: {},
  MuiToggleButtonGroup: {},
  MuiToolbar: {},
  MuiTooltip: {},
  MuiTypography: {
    variants: [
      {
        props: { variant: 'breadcrumb' },
        style: {
          color: '#575757',
          padding: 0,
          fontSize: '16px',
          fontWeight: 500,
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.09))',
        },
      },
    ],
  },

  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: 0,
        color: '#101828',
        fontFamily: "'Poppins'",
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-virtualScrollerContent': {
          maxWidth: '800px',
        },
        '& .MuiDataGrid-columnsContainer': {
          backgroundColor: '#fff',
        },
        '& .MuiDataGrid-iconSeparator': {
          display: 'none',
        },
        '&.MuiDataGrid-columnSeparator--sideRight': {
          display: 'none',
        },

        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-colCell:focus-within,  & .MuiDataGrid-columnHeader:focus-within':
          {
            outline: 0,
          },
        '& .MuiDataGrid-columnHeader--moving': {
          backgroundColor: 'unset',
        },
        '& .MuiDataGrid-columnHeaders': {
          position: 'sticky',
          zIndex: 5,
          top: 0,
          background: '#F9FAFB',
          color: '#667085',
          fontWeight: 500,
          fontSize: '12px',
          maxHeight: '44px !important',
          minHeight: '44px !important',
          lineHeight: '44px !important',
        },
        '& .MuiDataGrid-virtualScroller': {
          marginTop: '0 !important',
          minHeight: '250px',
        },
        '& .MuiDataGrid-main': {
          overflow: 'visible',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
          borderBottom: '1px solid #EAECF0',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          '&:focus': {
            outline: 'none',
          },
        },
        '& .MuiDataGrid-cell': {
          fontFamily: "'Poppins'",
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          color: '#000',
        },
        '& .MuiPaginationItem-root': {
          borderRadius: 0,
        },
      },
    },
  },
};
