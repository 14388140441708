import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppBar, Avatar, Box, IconButton, Menu, MenuItem, Toolbar, Tooltip } from '@mui/material';
import logo from '../../../assets/logos/headerLogo.webp';
import { useTranslation } from '../../../hooks';
import tokenServices from '../../../services/utils/tokenServices';
import { clearPresistedState } from '../../../utils';
import AppMobileMenu from './AppMobileMenu';
import NotificationBell from '../../../components/Notification/NotificationBell';
import { userSlice } from '../../../services/slices/user/userSlice';

const menuId = 'primary-search-account-menu';

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation();

  const { image } = userSlice.endpoints.getUserDetails.useQueryState(null, {
    selectFromResult: (res) => res.data || {},
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuClose = () => setAnchorEl(null);
  const handleProfileMenuOpen = (e) => setAnchorEl(e.currentTarget);

  const handleAccount = () => {
    handleMenuClose();
    navigate('/settings/account');
    //window.location.reload();
  };

  const handleLogout = async () => {
    dispatch({ type: 'logout' });
    navigate('/login', { replace: true });
    tokenServices.deleteToken();
    clearPresistedState();
    handleMenuClose();
    //window.location.reload();
  };

  const open = Boolean(anchorEl);

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      open={open}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      onClose={handleMenuClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
            mt: 1.5,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      }}
    >
      <MenuItem sx={{ minHeight: 0, borderBottom: '1px solid #EBEBEB' }} onClick={handleAccount}>
        {t('account')}
      </MenuItem>
      <MenuItem sx={{ minHeight: 0 }} color="danger" onClick={handleLogout}>
        {t('logOut')}
      </MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      <AppBar position="fixed" color="inherit">
        <Toolbar sx={{ height: (t) => t.dimentions.headerheight }}>
          <AppMobileMenu />
          <Avatar
            src={logo}
            variant="square"
            alt="Company Logo"
            sx={{
              width: '72px',
              backgroundColor: 'transparent',
              border: 'none',
              '& img': { objectFit: 'contain' },
            }}
          />
          <Box sx={{ flex: 1 }} />

          <NotificationBell badgeContent={20} />

          <Tooltip title="Account">
            <IconButton
              onClick={handleProfileMenuOpen}
              size="small"
              aria-haspopup="true"
              aria-controls={open ? 'account-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar
                sx={{ width: 28, height: 28, marginLeft: '7px' }}
                alt="Profile Image"
                src={image}
              />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </React.Fragment>
  );
};
