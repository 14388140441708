import React from 'react';
import PropTypes from 'prop-types';

const ServiceTicketsIcon = ({ style, fill }) => (
  <svg
    width="46"
    height="46"
    style={{ ...style }}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.88">
      <path
        d="M2.09366 18.7567C1.24053 17.8874 1.25264 16.4855 2.12123 15.6324L6.85045 10.989L7.36645 11.5148C8.40982 12.5772 10.1232 12.5933 11.1853 11.5502C12.247 10.5076 12.2627 8.79452 11.2201 7.7321L10.7041 7.20632L15.4322 2.56188C15.8532 2.14804 16.4103 1.92335 17 1.92888C17.5903 1.93442 18.1427 2.16916 18.5569 2.59119L43.3322 27.8217C44.1853 28.6909 44.1732 30.0928 43.3046 30.946L38.5754 35.5894L38.0598 35.0639C37.016 34.0012 35.3027 33.9851 34.2406 35.0281C33.1788 36.0708 33.1631 37.7838 34.2061 38.8466L34.7222 39.3724L29.994 44.0161C29.573 44.43 29.0159 44.6547 28.4259 44.6495C27.8359 44.6436 27.2828 44.4089 26.869 43.9872L2.09366 18.7567ZM6.87822 13.0261L3.15394 16.6832C2.86441 16.9674 2.86072 17.4348 3.14521 17.7247L27.9205 42.9552C28.0581 43.0954 28.2425 43.1739 28.4394 43.176C28.6359 43.1779 28.8214 43.1025 28.9616 42.9649L32.6862 39.3075C31.568 37.6703 31.7462 35.4124 33.2082 33.9769C34.6706 32.541 36.9314 32.4039 38.548 33.5519L42.2719 29.8951C42.5615 29.611 42.5655 29.1432 42.2813 28.8537L17.5057 3.62286C17.3681 3.48265 17.1837 3.40417 16.9868 3.40197C16.7903 3.40013 16.6052 3.47515 16.4646 3.61309L12.74 7.27056C13.8589 8.90847 13.68 11.1656 12.2177 12.6015C10.7553 14.0373 8.49417 14.1741 6.87822 13.0261Z"
        fill={fill}
        stroke={fill}
        strokeWidth="1.55215"
      />
      <g clipPath="url(#clip0_6971_1227)">
        <path
          d="M23.32 19.7795C23.1709 19.9315 23.0874 20.1359 23.0874 20.3489C23.0874 20.5618 23.1709 20.7662 23.32 20.9183L24.6214 22.2197C24.7735 22.3688 24.9779 22.4522 25.1908 22.4522C25.4037 22.4522 25.6082 22.3688 25.7602 22.2197L28.8268 19.1531C29.2358 20.057 29.3596 21.064 29.1818 22.0401C29.004 23.0161 28.5329 23.9148 27.8314 24.6163C27.1299 25.3178 26.2312 25.7889 25.2552 25.9667C24.2792 26.1445 23.2721 26.0207 22.3683 25.6117L16.7475 31.2324C16.4239 31.556 15.985 31.7378 15.5274 31.7378C15.0698 31.7378 14.6309 31.556 14.3073 31.2324C13.9837 30.9088 13.8019 30.4699 13.8019 30.0123C13.8019 29.5546 13.9837 29.1157 14.3073 28.7921L19.928 23.1714C19.519 22.2676 19.3951 21.2605 19.573 20.2845C19.7508 19.3085 20.2219 18.4098 20.9234 17.7083C21.6249 17.0068 22.5236 16.5357 23.4996 16.3579C24.4756 16.18 25.4827 16.3039 26.3865 16.7129L23.3281 19.7713L23.32 19.7795Z"
          stroke={fill}
          strokeWidth="1.55215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_6971_1227">
        <rect
          width="24.1238"
          height="22.7836"
          fill="white"
          transform="translate(23.6814 6.2312) rotate(45)"
        />
      </clipPath>
    </defs>
  </svg>
);

ServiceTicketsIcon.propTypes = { fill: PropTypes.string, style: PropTypes.any };

ServiceTicketsIcon.defaultProps = { fill: '#575757', style: {} };

export default ServiceTicketsIcon;
