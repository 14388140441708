export default {
  palette: {
    mode: 'light',
    primary: { main: '#3DC4C3', secondary: '#757575', tertiary: '#575757', contrastText: '#FFF' },
    secondary: { main: '#f50057' },
    text: {
      primary: '#101828',
      secondary: '#757575',
      tertiary: '#575757',
      disabled: '#000',
      hint: '#575757',
    },
    info: { light: '#0E9CFF', main: '#1F91D0', dark: '#0E9CFF' },
  },
  typography: {
    fontFamily: 'Poppins',
    fontSize: 13,
    fontWeightLight: 400,
    fontWeightBold: 600,
    color: '#101828',
    h1: { color: '#101828', fontSize: '30px', fontWeight: 700, lineHeight: '38px' },
    h2: { color: '#101828', fontSize: '24px', fontWeight: 600, lineHeight: '30px' },
    h3: { color: '#101828', fontSize: '20px', fontWeight: 500, lineHeight: '26px' },
    h4: { color: '#101828', fontSize: '16px', fontWeight: 500, lineHeight: '24px' },
    h5: { fontSize: '14px', fontWeight: 500, lineHeight: '20px', color: '#101828' },
    subtitle1: { color: '#101828', fontSize: '16px', lineHeight: '24px' },
    body1: { fontSize: '14px', color: '#101828', fontWeight: 400, lineHeight: '20px' },
    body2: { fontSize: '13px', color: '#101828', fontWeight: 400, lineHeight: '18px' },
    caption: { color: '#757575', fontSize: '14px', lineHeight: '24px' },
    button: { fontWeight: 500, fontSize: '14px', textTransform: 'none' },
  },
};
