import React from 'react';
import { RouterProvider } from 'react-router-dom';

import routes from './routes';
import Fallback from './components/ProgressBar/CircularProgress';

function App() {
  return (
    <React.Suspense fallback={<Fallback />}>
      <RouterProvider fallbackElement={<Fallback />} router={routes([])} />
    </React.Suspense>
  );
}

export default App;
