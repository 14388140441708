import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
import {
  altitudeFromMeters,
  altitudeUnitString,
  distanceFromMeters,
  distanceUnitString,
  speedFromKnots,
  speedUnitString,
  volumeFromLiters,
  volumeUnitString,
} from './converter';
import { prefixString } from './stringUtils';
import { typography } from './language';

export const formatBoolean = (value) => (value ? typography('yes') : typography('no'));

export const formatVoltage = (value) => `${value} ${typography('sharedVoltAbbreviation')}`;

export const formatNumber = (value, precision = 1) => Number(value?.toFixed(precision));

export const formatPercentage = (value) => `${value}%`;

export const formatTemperature = (value) => `${value || ''}°C`;

export const dateFormats = { DATE: 'date', TIME: 'time', MINUTES: 'minutes', SECONDS: 'seconds' };

export const formatTime = (value, formatType, hours12) => {
  if (value) {
    const date = dayjs(value);
    switch (formatType) {
      case dateFormats.DATE:
        return date.format('DD-MM-YYYY');
      case dateFormats.TIME:
        return date.format(hours12 ? 'hh:mm:ss a' : 'HH:mm:ss');
      case dateFormats.MINUTES:
        return date.format(hours12 ? 'DD-MM-YYYY hh:mm a' : 'DD-MM-YYYY HH:mm');
      case dateFormats.SECONDS:
        return date.format(hours12 ? 'DD-MM-YYYY hh:mm a' : 'DD-MM-YYYY HH:mm');
      default:
        return date.format(formatType);
    }
  }
  return '';
};

export const formatStatus = (value) => typography(prefixString('deviceStatus', value));
export const formatAlarm = (value) => (value ? typography(prefixString('alarm', value)) : '');

export const formatCourse = (value) => {
  const courseValues = [
    '\u2191',
    '\u2197',
    '\u2192',
    '\u2198',
    '\u2193',
    '\u2199',
    '\u2190',
    '\u2196',
  ];
  let normalizedValue = (value + 45 / 2) % 360;
  if (normalizedValue < 0) {
    normalizedValue += 360;
  }
  return courseValues[Math.floor(normalizedValue / 45)];
};

export const formatDistance = (value, unit) =>
  `${distanceFromMeters(value, unit).toFixed(2)} ${distanceUnitString(unit)}`;

export const formatAltitude = (value, unit) =>
  `${altitudeFromMeters(value, unit).toFixed(2)} ${altitudeUnitString(unit)}`;

export const formatSpeed = (value = 0, unit) =>
  `${speedFromKnots(value, unit).toFixed(0)} ${speedUnitString(unit)}`;

export const formatVolume = (value, unit) =>
  `${volumeFromLiters(value, unit).toFixed(2)} ${volumeUnitString(unit)}`;

export const formatNumericHours = (value) => {
  const hours = Math.floor(value / 3600000);
  const minutes = Math.floor((value % 3600000) / 60000);
  return `${hours} ${typography('sharedHourAbbreviation')} ${minutes} ${typography(
    'sharedMinuteAbbreviation',
  )}`;
};

export const formatCoordinate = (key, value, unit) => {
  let hemisphere;
  let degrees;
  let minutes;
  let seconds;

  if (key === 'latitude') {
    hemisphere = value >= 0 ? 'N' : 'S';
  } else {
    hemisphere = value >= 0 ? 'E' : 'W';
  }

  switch (unit) {
    case 'ddm':
      value = Math.abs(value);
      degrees = Math.floor(value);
      minutes = (value - degrees) * 60;
      return `${degrees}° ${minutes.toFixed(6)}' ${hemisphere}`;
    case 'dms':
      value = Math.abs(value);
      degrees = Math.floor(value);
      minutes = Math.floor((value - degrees) * 60);
      seconds = Math.round((value - degrees - minutes / 60) * 3600);
      return `${degrees}° ${minutes}' ${seconds}" ${hemisphere}`;
    default:
      return `${value.toFixed(6)}°`;
  }
};

export const formatNotificationTitle = (notification, includeId) => {
  let title = typography(prefixString('event', notification.type));
  if (notification.type === 'alarm') {
    const alarmString = notification.attributes.alarms;
    if (alarmString) {
      const alarms = alarmString.split(',');
      if (alarms.length > 1) {
        title += ` (${alarms.length})`;
      } else {
        title += ` ${formatAlarm(alarms[0])}`;
      }
    }
  }
  if (includeId) {
    title += ` [${notification.id}]`;
  }
  return title;
};

export const formatValue = (data, key) => {
  switch (key) {
    case 'motorValue':
      return `${data[key] || ''} KW`;
    case 'voltage':
      return `${data[key] || ''} V`;
    default:
      return data[key] || '';
  }
};

export const filterOptions = [
  { label: 'Today', value: 'Today' },
  { label: 'Yesterday', value: 'Yesterday' },
  { label: 'Last week', value: 'Last week' },
  { label: 'Last month', value: 'Last month' },
  { label: 'Last 6 months', value: 'Last 6 months' },
  { label: 'Last 12 months', value: 'Last 12 months' },
];

const monthDaysMap = {
  Jan: 31,
  Feb: 29,
  Sept: 30,
  Oct: 31,
  Nov: 30,
  Dec: 31,
};
const monthAsnumber = {
  Jan: 0,
  Feb: 1,
  Sept: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11,
};

export const generateDatesForGraph = (month) => {
  const category = [];

  const date = dayjs(new Date()).set('M', monthAsnumber[month]).startOf('M').toDate();

  let dottedBase = +date;

  for (let i = 0; i < (monthDaysMap[month] || 30); i++) {
    let date = new Date(dottedBase);
    category.push([date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-'));
    dottedBase += 3600 * 24 * 1000;
  }
  return category;
};

export const getMonthsAsOptions = (nofMonths) => {
  const months = [];
  const today = new Date();
  for (let i = 0; i < nofMonths; i++) {
    let month = today.getMonth() - i;
    let year = today.getFullYear();
    if (month < 0) {
      month += 12;
      year -= 1;
    }
    const monthValue = new Date(year, month).toLocaleString('default', { month: 'short' });
    const monthLabel = new Date(year, month).toLocaleString('default', {
      month: 'long',
      year: 'numeric',
    });
    months.push({ label: monthLabel, value: monthValue });
  }
  return months;
};

export const getTimeRange = (option) => {
  const now = dayjs();

  let startDate;
  let endDate = now; // Current time

  switch (option) {
    case 'Today':
      startDate = now.startOf('day'); // Today's 12:00:00 am
      endDate = now; // Current time
      break;
    case 'Yesterday':
      startDate = now.subtract(1, 'day').startOf('day'); // Yesterday's 12:00:00 am
      endDate = now.subtract(1, 'day').endOf('day'); // Yesterday's 11:59:59 pm
      break;
    case 'Last week':
      startDate = now.subtract(1, 'week').startOf('day'); // 1 week ago from today's 12:00:00 am
      break;
    case 'Last month':
      startDate = now.subtract(1, 'month').startOf('day'); // 1 month ago from today's 12:00:00 am
      break;
    case 'Last 6 months':
      startDate = now.subtract(6, 'month').startOf('day'); // 6 months ago from today's 12:00:00 am
      break;
    case 'Last 12 months':
      startDate = now.subtract(1, 'year').startOf('day'); // 1 year ago from today's 12:00:00 am
      break;
    default:
      // Default to Today if the selected option is not recognized
      startDate = now.startOf('day'); // Today's 12:00:00 am
      break;
  }

  const startTimestamp = Math.floor(startDate.unix()); // Convert dayjs timestamp to Unix timestamp (seconds)
  const endTimestamp = Math.floor(endDate.unix()); // Convert dayjs timestamp to Unix timestamp (seconds)
  const duration = endTimestamp - startTimestamp;

  // Format UTC date strings in ISO 8601 format
  const startUTC = dayjs.unix(startTimestamp).toISOString();
  const endUTC = dayjs.unix(endTimestamp).toISOString();

  return { start: startTimestamp, end: endTimestamp, duration, startUTC, endUTC };
};

export const shareLinkTimeRanges = { '2Hr': '2 hr', '4Hr': '4 hr', '8Hr': '8 hr' };

export const getTimeRangeForHours = (option) => {
  const now = new Date();
  const hoursMatch = option.match(/(\d+) hr/);

  if (hoursMatch) {
    const hours = parseInt(hoursMatch[1], 10);
    const endTimestamp = Math.floor(now.getTime() / 1000) + hours * 60 * 60; // Calculate end time as 'now + hours' in seconds

    return {
      start: Math.floor(now.getTime() / 1000),
      end: endTimestamp,
      duration: hours * 60 * 60,
    };
  }

  return { start: '', end: '', duration: 0 }; // Invalid or unsupported option
};

export const epochTimeFormatter = (epochTimestamp, formatString) => {
  if (!epochTimestamp) return '';
  const date = new Date(epochTimestamp * 1000); // Convert epoch to milliseconds
  return dayjs(date).format(formatString);
};

dayjs.extend(duration);
export const humanizeEpochTimestamp = (seconds) => {
  const duration = dayjs.duration(seconds, 'seconds');
  const days = Math.floor(duration.asDays());
  const remainingHours = duration.hours();
  const minutes = duration.minutes();
  //const remainingSeconds = duration.seconds();

  let humanized = '';
  if (days > 0) {
    humanized += `${days} ${days === 1 ? 'day' : 'days'}`;
  }
  if (remainingHours > 0) {
    humanized += `${humanized ? ' ' : ''}${remainingHours} ${remainingHours === 1 ? 'hr' : 'hrs'}`;
  }
  if (minutes > 0) {
    humanized += `${humanized ? ' ' : ''}${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
  }
  // if (remainingSeconds > 0) {
  //   humanized += `${humanized ? ' ' : ''}${remainingSeconds} ${
  //     remainingSeconds === 1 ? 'sec' : 'seconds'
  //   }`;
  // }

  return humanized || 0;
};

export const localeDateString = (date) =>
  date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

export const isDateWithinRange = () => {
  dayjs.extend(isBetween);
  return (date, ...ranges) => (date ? dayjs(date).isBetween(...ranges) : false); // Just in case if date is undefined
};

export const getAge = (date) => dayjs().diff(dayjs(date), 'years');

export const humanizeDate = (date) => {
  const currentDate = new Date();
  const startDate = new Date(date);
  const timeDifference = currentDate - startDate;
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);

  if (secondsDifference < 60) {
    return 'Just now';
  }
  if (minutesDifference < 60) {
    return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
  }
  if (hoursDifference < 24) {
    return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
  }
  if (daysDifference === 1) {
    return 'Yesterday';
  }
  if (daysDifference < 7) {
    return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
  }
  return startDate.toDateString();
};

export const humanizeDateTillWeeksMonthsAndYears = (date) => {
  const currentDate = new Date();
  const startDate = new Date(date);
  const timeDifference = currentDate - startDate;
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);
  const weeksDifference = Math.floor(daysDifference / 7);
  const monthsDifference = Math.floor(daysDifference / 30);

  if (secondsDifference < 60) {
    return 'Just now';
  }
  if (minutesDifference < 60) {
    return `${minutesDifference} ${minutesDifference === 1 ? 'minute ago' : 'minutes ago'}`;
  }
  if (hoursDifference < 24) {
    return `${hoursDifference} ${hoursDifference === 1 ? 'hour ago' : 'hours ago'}`;
  }
  if (daysDifference === 1) {
    return 'Yesterday';
  }
  if (daysDifference < 7) {
    return `${daysDifference} ${daysDifference === 1 ? 'day ago' : 'days ago'}`;
  }
  if (weeksDifference === 1) {
    return '1 week ago ';
  }
  if (weeksDifference > 1 && weeksDifference < 4) {
    return `${weeksDifference} weeks ago`;
  }
  if (monthsDifference === 1) {
    return '1 month ago';
  }
  if (monthsDifference === 12) {
    return '1 year ago';
  }
  if (monthsDifference > 1 && monthsDifference < 12) {
    return `${monthsDifference} months ago`;
  }
  if (monthsDifference > 12) {
    return `${(monthsDifference / 12).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} years ago`;
  }
  return date.toDateString();
};

export const validateIsAtLeast18YearsOld = (birthdate) =>
  dayjs().diff(dayjs(birthdate), 'year') >= 18;
