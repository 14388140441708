import React from 'react';
import PropTypes from 'prop-types';

const RidersIcon = ({ fill, style }) => (
  <svg
    style={{ ...style }}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 7.97656C6.9 7.97656 6 7.62656 5.3 6.92656C4.6 6.22656 4.25 5.32656 4.25 4.22656C4.25 3.12656 4.6 2.22656 5.3 1.52656C6 0.826562 6.9 0.476562 8 0.476562C9.1 0.476562 10 0.826562 10.7 1.52656C11.4 2.22656 11.75 3.12656 11.75 4.22656C11.75 5.32656 11.4 6.22656 10.7 6.92656C10 7.62656 9.1 7.97656 8 7.97656ZM14.5 16.0016H1.5C1.0875 16.0016 0.734375 15.8547 0.440625 15.5609C0.146875 15.2672 0 14.9141 0 14.5016V13.6516C0 13.0182 0.158333 12.4766 0.475 12.0266C0.791667 11.5766 1.2 11.2349 1.7 11.0016C2.81667 10.5016 3.8875 10.1266 4.9125 9.87656C5.9375 9.62656 6.96667 9.50156 8 9.50156C9.03333 9.50156 10.0583 9.63073 11.075 9.88906C12.0917 10.1474 13.1577 10.5198 14.273 11.0064C14.7947 11.2418 15.213 11.5832 15.5278 12.0306C15.8426 12.4779 16 13.0182 16 13.6516V14.5016C16 14.9141 15.8531 15.2672 15.5594 15.5609C15.2656 15.8547 14.9125 16.0016 14.5 16.0016ZM1.5 14.5016H14.5V13.6516C14.5 13.3849 14.4208 13.1307 14.2625 12.8891C14.1042 12.6474 13.9083 12.4682 13.675 12.3516C12.6083 11.8349 11.6333 11.4807 10.75 11.2891C9.86667 11.0974 8.95 11.0016 8 11.0016C7.05 11.0016 6.125 11.0974 5.225 11.2891C4.325 11.4807 3.35 11.8349 2.3 12.3516C2.06667 12.4682 1.875 12.6474 1.725 12.8891C1.575 13.1307 1.5 13.3849 1.5 13.6516V14.5016ZM8 6.47656C8.65 6.47656 9.1875 6.26406 9.6125 5.83906C10.0375 5.41406 10.25 4.87656 10.25 4.22656C10.25 3.57656 10.0375 3.03906 9.6125 2.61406C9.1875 2.18906 8.65 1.97656 8 1.97656C7.35 1.97656 6.8125 2.18906 6.3875 2.61406C5.9625 3.03906 5.75 3.57656 5.75 4.22656C5.75 4.87656 5.9625 5.41406 6.3875 5.83906C6.8125 6.26406 7.35 6.47656 8 6.47656Z"
      fill={fill}
    />
  </svg>
);
RidersIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.any,
};

RidersIcon.defaultProps = {
  fill: '#575757',
  style: {},
};

export default RidersIcon;
