import React from 'react';

export const Page403 = React.lazy(() => import('./others/Page403'));
export const Page404 = React.lazy(() => import('./others/Page404'));
export const Page500 = React.lazy(() => import('./others/Page500'));

export const ResetPassword = React.lazy(() => import('./others/ResetPassword'));

export const WelcomePage = React.lazy(() => import('./others/WelcomePage'));

export const RecoveryTrackPage = React.lazy(() => import('./share/RecoveryTrackPage'));

export const RegisterPage = React.lazy(() => import('./register/Register'));
export const LoginPage = React.lazy(() => import('./login/Login'));
export const VerifyPage = React.lazy(() => import('./verify/Verify'));

export const DashboardPage = React.lazy(() => import('./dashboard/Dashboard'));

export const TrackPage = React.lazy(() => import('./track/Track'));
export const AttributesPage = React.lazy(() => import('./track/Attributes'));

export const TripsPage = React.lazy(() => import('./trips/Trips'));
export const AllTripsPage = React.lazy(() => import('./trips/AllTrips'));
export const ReplayPage = React.lazy(() => import('./trips/Replay'));
export const AlertsPage = React.lazy(() => import('./trips/Alerts'));
export const ReportsPage = React.lazy(() => import('./trips/Reports'));

export const VehicleAnalyticsPage = React.lazy(() => import('./analytics/VehicleAnalytics'));

export const RidersPage = React.lazy(() => import('./riders/Riders'));

export const ServiceTicketsPage = React.lazy(() => import('./serviceTickets/ServiceTickets'));
export const VehiclePage = React.lazy(() => import('./vehicles/Vehicles'));

export const AccountPage = React.lazy(() => import('./settings/account/Account'));
export const SecurityPage = React.lazy(() => import('./settings/security/Security'));
