import { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

export default () => {
  const theme = useTheme();

  const betweenMD_LG = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  // const isMobile = width <= 768;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isTablet = width <= 1024;
  const isDesktop = width > 1024;

  return { isMobile, isTablet, isDesktop, betweenMD_LG };
};
