import { createSlice } from '@reduxjs/toolkit';

const { actions, reducer } = createSlice({
  name: 'share',
  initialState: { positions: {} },
  reducers: {
    updatePositions(state, action) {
      return {
        ...state,
        positions: {
          ...state.positions,
          [action.payload._trackId]: {
            ...(state.positions[action.payload._trackId] || {}),
            ...action.payload,
          },
        },
      };
    },
  },
});

export { actions as shareActions, reducer as shareReducer };
