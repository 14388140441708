import React, { createContext, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getDefaultLanguage } from '../utils';
import usePersistedState from '../hooks/usePersistedState';
import languages from '../data/languages';

const TypographyLocalizationContext = createContext({
  languages,
  language: 'en',
  setLanguage: () => {},
});

export default function TypographyLocalizationProvider({ children }) {
  const [language, setLanguage] = usePersistedState('language', getDefaultLanguage());

  const value = useMemo(
    () => ({ languages, language, setLanguage }),
    [languages, language, setLanguage],
  );

  useEffect(() => {
    let selected;
    if (language === 'zh') {
      selected = 'zh-cn';
    } else if (language.length > 2) {
      selected = `${language.slice(0, 2)}-${language.slice(-2).toLowerCase()}`;
    } else {
      selected = language;
    }
  }, [language]);

  return (
    <TypographyLocalizationContext.Provider value={value}>
      {children}
    </TypographyLocalizationContext.Provider>
  );
}

TypographyLocalizationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTypographyLocalization = () => useContext(TypographyLocalizationContext);
