import { combineReducers, configureStore } from '@reduxjs/toolkit';
import throttleMiddleware from './throttleMiddleware';

import { errorsReducer as errors } from './errors';
import { trackReducer as track } from './track';
import { shareReducer as share } from './share';

import rtkQueryErrorsMiddleware from './rtkQueryErrorsMiddleware';
import msilApi from '../services/msilApi';

const reducer = combineReducers({
  errors,
  track,
  share,
  [msilApi.reducerPath]: msilApi.reducer,
});

export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat([
      throttleMiddleware,
      rtkQueryErrorsMiddleware,
      msilApi.middleware,
    ]),
});
