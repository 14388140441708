import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { List, ListItem, ListItemButton, Stack, Tooltip, Typography } from '@mui/material';
// import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';

import { FE_PAGES } from '../../../data/authGaurd/pages';
import DashboardIcon from '../../../assets/icons/dashboardIcon';
import TrackIcon from '../../../assets/icons/trackIcon';
import VehiclesIcon from '../../../assets/icons/vehiclesIcon';
import RidersIcon from '../../../assets/icons/ridersIcon';
import ServiceTicketsIcon from '../../../assets/icons/serviceTicketsIcon';
import SettingsIcon from '../../../assets/icons/settingsIcon';
import InsightsAnalytics from '../../../assets/icons/insightsAnalyticsIcon';

const SideBarItem = ({ link, selected, Icon, title, iconStyles = {} }) => (
  <Tooltip title={title} placement="right" arrow>
    <ListItem style={{ paddingRight: 0 }}>
      <ListItemButton
        LinkComponent={Link}
        to={link}
        sx={{ borderRadius: '8px 0px 0px 8px' }}
        selected={selected}
      >
        <Icon
          style={{ margin: 'auto', ...iconStyles }}
          fill={selected ? '#101828' : '#757575'}
          stroke={selected ? '#101828' : '#757575'}
        />
      </ListItemButton>
    </ListItem>
  </Tooltip>
);

const MobileSideBarItem = ({ link, selected, Icon, title, iconStyles = {}, handleOnClick }) => (
  <ListItem sx={{ padding: 0 }}>
    <ListItemButton
      onClick={handleOnClick}
      sx={{ padding: '4px 8px' }}
      LinkComponent={Link}
      to={link}
      selected={selected}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ width: '50px', height: '50px' }}>
        <Icon
          style={{ ...iconStyles }}
          fill={selected ? '#101828' : '#757575'}
          stroke={selected ? '#101828' : '#757575'}
        />
      </Stack>
      <Typography
        variant="h5"
        color={(t) => (selected ? t.palette.text.primary : t.palette.text.secondary)}
      >
        {title}
      </Typography>
    </ListItemButton>
  </ListItem>
);

const sideBarItemsMenu = [
  { code: FE_PAGES.DASHBOARD, icon: DashboardIcon, link: 'dashboard', title: 'Dashboard' },
  { code: FE_PAGES.TRACK, icon: TrackIcon, link: 'track', title: 'Track' },
  { code: FE_PAGES.VEHICLES, icon: VehiclesIcon, link: 'vehicles', title: 'Vehicles' },

  { code: FE_PAGES.RIDERS, icon: RidersIcon, link: 'riders', title: 'Riders' },
];

const sideBarItemsMenu1 = [
  {
    code: FE_PAGES.SERVICE_TICKETS,
    icon: ServiceTicketsIcon,
    link: 'serviceTickets',
    iconStyle: { width: '24px', height: '24px' },
    title: 'Service Tickets',
  },

  {
    code: FE_PAGES.REPORTS,
    icon: InsightsAnalytics,
    link: '/insights/ride',
    title: 'Insights & Analytics',
  },
  { code: FE_PAGES.ACCOUNT, icon: SettingsIcon, link: 'settings/account', title: 'Settings' },
];

const routeConfig = [
  { pattern: /^\/dashboard/, page: FE_PAGES.DASHBOARD },
  { pattern: /^\/track/, page: FE_PAGES.TRACK },
  { pattern: /^\/vehicles/, page: FE_PAGES.VEHICLES },
  { pattern: /^\/settings/, page: FE_PAGES.ACCOUNT },
  { pattern: /^\/insights/, page: FE_PAGES.REPORTS },
  { pattern: /^\/serviceTickets/, page: FE_PAGES.SERVICE_TICKETS },
  { pattern: /^\/riders/, page: FE_PAGES.RIDERS },
];

const mapRouteToPage = (route) =>
  routeConfig.find(({ pattern }) => pattern.test(route))?.page || '';

export const NavMenuOptions = ({ isMobile = false, handleOnClick }) => {
  const location = useLocation();
  const menuItems = sideBarItemsMenu;
  const menuItem1 = sideBarItemsMenu1;

  const selected = useMemo(() => mapRouteToPage(location.pathname), [location.pathname]);

  return (
    <React.Fragment>
      <Stack flex={1} direction="column">
        {menuItems.map(({ link, code, icon, iconStyle = {}, title }) =>
          isMobile ? (
            <MobileSideBarItem
              handleOnClick={handleOnClick}
              key={code}
              link={link}
              selected={selected === code}
              Icon={icon}
              title={title}
              iconStyles={iconStyle}
            />
          ) : (
            <SideBarItem
              key={code}
              link={link}
              selected={selected === code}
              Icon={icon}
              title={title}
              iconStyles={iconStyle}
            />
          ),
        )}
      </Stack>
      <Stack flex={1} direction="column">
        {menuItem1.map(({ link, code, icon, iconStyle = {}, title }) =>
          isMobile ? (
            <MobileSideBarItem
              key={code}
              link={link}
              selected={selected === code}
              Icon={icon}
              title={title}
              iconStyles={iconStyle}
            />
          ) : (
            <SideBarItem
              key={code}
              link={link}
              selected={selected === code}
              Icon={icon}
              title={title}
              iconStyles={iconStyle}
            />
          ),
        )}
      </Stack>
    </React.Fragment>
  );
};

const AppSideBar = () => (
  <List
    sx={{
      background: '#fff',
      zIndex: 32,
      position: 'fixed',
      display: { md: 'flex', xs: 'none' },
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRight: '1px solid #EBEBEB',
      gap: { md: '32px', xl: '64px' },
      overflow: 'scroll',
      '&::-webkit-scrollbar': { display: 'none' },
      height: (t) => `calc(${t.dimentions.sidebarHeight} - ${t.dimentions.headerheight})`,
      width: (t) => t.dimentions.sidebarWidth,
      pt: (t) => t.dimentions.headerheight,
    }}
  >
    <NavMenuOptions />
  </List>
);

export default AppSideBar;
