import { combineReducers, createSelector, createSlice } from '@reduxjs/toolkit';
import { superAdminTrackTabs } from '../features/track/context/SuperAdminTrackProvider';

const positionsSlice = createSlice({
  name: 'positions',
  initialState: {},
  reducers: {
    update(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const { update: updatePositions } = positionsSlice.actions;

const devicesSlice = createSlice({
  name: 'devices',
  initialState: { vehicles: {}, selectedId: null },
  reducers: {
    updateTrackVehicles(state, action) {
      return {
        ...state,
        vehicles: (action?.payload || [])?.reduce(
          (acc, veh) => ({
            ...acc,
            [veh?.deviceId]: { ...(state.vehicles[veh?.deviceId] || {}), ...veh },
          }),
          {},
        ),
      };
    },
    selectId(state, { payload }) {
      return { ...state, selectedId: payload.deviceId };
    },
  },
});

export const { updateTrackVehicles, selectId } = devicesSlice.actions;

export const trackReducer = combineReducers({
  positions: positionsSlice.reducer,
  devices: devicesSlice.reducer,
});

const selectTrack = (state) => state.track;

const selectDevices = createSelector([selectTrack], (state) => state.devices);

export const selectPositions = createSelector([selectTrack], (state) => state.positions);

export const createPositionByIdSelector = (deviceId) =>
  createSelector([selectPositions], (state) => state[deviceId]);

export const createLatitudeSelector = (deviceId) =>
  createSelector([createPositionByIdSelector(deviceId)], (state) => state?.latitude);

export const createLongitudeSelector = (deviceId) =>
  createSelector([createPositionByIdSelector(deviceId)], (state) => state?.longitude);

export const createCourseSelector = (deviceId) =>
  createSelector([createPositionByIdSelector(deviceId)], (state) => state?.course);

export const selectVehiclesList = createSelector([selectDevices], (state) => state.vehicles);

export const selectBatteriesList = createSelector([selectDevices], (state) => state.batteries);

export const selectSelectedId = createSelector([selectDevices], (state) => state.selectedId);

export const createSelectedDeviceSelector = () =>
  createSelector(
    [selectSelectedId, selectVehiclesList, selectBatteriesList],
    (selectedId, vehicles, batteries) => vehicles[selectedId] || batteries[selectedId],
  );

export const SelectedDeviceCategorySelector = () =>
  createSelector(
    [selectSelectedId, selectVehiclesList, selectBatteriesList],
    (selectedId, vehicles, batteries) =>
      vehicles[selectedId]
        ? superAdminTrackTabs.VEHICLES
        : batteries[selectedId]
        ? superAdminTrackTabs.BATTERIES
        : '',
  );
