import React from 'react';
import PropTypes from 'prop-types';

const DashboardIcon = ({ fill, style }) => (
  <svg
    style={{ ...style }}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12.75 9V3.75C12.75 3.5375 12.8219 3.35938 12.9656 3.21563C13.1094 3.07188 13.2875 3 13.5 3H20.25C20.4625 3 20.6406 3.07188 20.7844 3.21563C20.9281 3.35938 21 3.5375 21 3.75V9C21 9.2125 20.9281 9.39062 20.7844 9.53438C20.6406 9.67813 20.4625 9.75 20.25 9.75H13.5C13.2875 9.75 13.1094 9.67813 12.9656 9.53438C12.8219 9.39062 12.75 9.2125 12.75 9ZM3 12V3.75C3 3.5375 3.07188 3.35938 3.21563 3.21563C3.35938 3.07188 3.5375 3 3.75 3H10.5C10.7125 3 10.8906 3.07188 11.0344 3.21563C11.1781 3.35938 11.25 3.5375 11.25 3.75V12C11.25 12.2125 11.1781 12.3906 11.0344 12.5344C10.8906 12.6781 10.7125 12.75 10.5 12.75H3.75C3.5375 12.75 3.35938 12.6781 3.21563 12.5344C3.07188 12.3906 3 12.2125 3 12ZM12.75 20.25V12C12.75 11.7875 12.8219 11.6094 12.9656 11.4656C13.1094 11.3219 13.2875 11.25 13.5 11.25H20.25C20.4625 11.25 20.6406 11.3219 20.7844 11.4656C20.9281 11.6094 21 11.7875 21 12V20.25C21 20.4625 20.9281 20.6406 20.7844 20.7844C20.6406 20.9281 20.4625 21 20.25 21H13.5C13.2875 21 13.1094 20.9281 12.9656 20.7844C12.8219 20.6406 12.75 20.4625 12.75 20.25ZM3 20.25V15C3 14.7875 3.07188 14.6094 3.21563 14.4656C3.35938 14.3219 3.5375 14.25 3.75 14.25H10.5C10.7125 14.25 10.8906 14.3219 11.0344 14.4656C11.1781 14.6094 11.25 14.7875 11.25 15V20.25C11.25 20.4625 11.1781 20.6406 11.0344 20.7844C10.8906 20.9281 10.7125 21 10.5 21H3.75C3.5375 21 3.35938 20.9281 3.21563 20.7844C3.07188 20.6406 3 20.4625 3 20.25ZM4.5 11.25H9.75V4.5H4.5V11.25ZM14.25 19.5H19.5V12.75H14.25V19.5ZM14.25 8.25H19.5V4.5H14.25V8.25ZM4.5 19.5H9.75V15.75H4.5V19.5Z"
      fill={fill}
    />
  </svg>
);

DashboardIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.any,
};

DashboardIcon.defaultProps = {
  fill: '#575757',
  style: {},
};

export default DashboardIcon;
