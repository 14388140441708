import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { errorsActions } from '../store/errors';

export default (effect, deps) => {
  const dispatch = useDispatch();
  const ref = useRef();
  useEffect(() => {
    effect()
      .then((result) => {
        ref.current = result;
      })
      .catch(async (error) =>
        dispatch(errorsActions.push({ data: error.message, status: error?.status })),
      );

    return () => {
      const result = ref.current;
      if (result) {
        result();
      }
    };
  }, [...deps, dispatch]);
};
