export const deviceStatus = {
  CHARGING: 'charging',
  DISCHARGING: 'discharging',
  ACTIVE: 'ACTIVE',
  IDLE: 'idle',
  INACTIVE: 'INACTIVE',
  NODATA: 'noData',
  STOPPED: 'stopped',
};

export const statusMap = {
  [deviceStatus.ACTIVE]: {
    label: 'Active',
    chipVariant: 'device-running',
    color: '#008000',
    borderColor: '#008000',
    backgroundColor: '#EBF5EB',
  },
  [deviceStatus.IDLE]: {
    label: 'Idle',
    chipVariant: 'device-idle',
    color: '#EC9C0B',
    borderColor: '#EC9C0B',
    backgroundColor: '#FEF7EC',
  },
  [deviceStatus.STOPPED]: {
    label: 'stopped',
    chipVariant: 'device-stopped',

    color: '#F00707',
    borderColor: '#F00707',
    backgroundColor: '#FEECEC',
  },
  [deviceStatus.INACTIVE]: {
    label: 'In Active',
    chipVariant: 'device-inactive',
    color: '#FFAA00',
    borderColor: '#FFAA00',
    backgroundColor: '#FEF7EC',
  },
  [deviceStatus.NODATA]: {
    label: 'noData',
    chipVariant: 'device-noData',
    color: '#808080',
    borderColor: '#808080',
    backgroundColor: '#F8F8F8',
  },
  [deviceStatus.CHARGING]: {
    typeSpecific: 'BATTERIES',
    color: '#1F91D0',
    label: 'charging',
    chipVariant: 'device-charging',
    borderColor: '#0E9CFF',
    backgroundColor: '#CCEAFF',
  },
  [deviceStatus.DISCHARGING]: {
    typeSpecific: 'BATTERIES',
    color: '#D78484',
    label: 'discharging',
    chipVariant: 'device-discharging',
    borderColor: '#D78484',
    backgroundColor: '#FEF7EC',
  },
};
