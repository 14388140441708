/* 
  Right now the api is returning certain page keys assuming diffrent page for each role
  But in [project] we have same page retuning diffrent features ||  widgets based on feature keys
*/

const apiPages = {
  // Super-admin
  SUPER_ADMIN_DASHBOARD: '2eb61b07',
  SUPER_ADMIN_TRACKING: '3d9cc0dc',
  SUPER_ADMIN_VEHICLES: 'a4152f87',
  SUPER_ADMIN_BATTERIES: '3db93f3f',
  SUPER_ADMIN_HUBS: '2d2e3eee',
  SUPER_ADMIN_TENANTS: '43cf3ed3',
  SUPER_ADMIN_RIDERS: '8f80ea89',
  SUPER_ADMIN_SERVICE_TICKETS: '019111e7',
  SUPER_ADMIN_TEAMS: 'd237bd3e',
  SUPER_ADMIN_REPORTS: '66fbd44c',

  SUPER_ADMIN_RENTAL_FOLLOW_UPS: '225ac51b',

  SUPER_ADMIN_ALLOCATED_FOR_RECOVERY: '5b70268f',

  SUPER_ADMIN_CREDIT_LOGS: '95e5238b',

  // Tenant-admin
  TENANT_ADMIN_DASHBOARD: '519d2ce2',
  TENANT_ADMIN_TRACKING: 'c1ff4927',
  TENANT_ADMIN_VEHICLES: '7bd61d4d',
  TENANT_ADMIN_RIDERS: '0ba32515',
  TENANT_ADMIN_SERVICE_TICKETS: '4f2347d5',
  TENANT_ADMIN_TEAMS: '6161a819',

  // Hub-admin
  HUB_ADMIN_DASHBOARD: 'f6c605a3',
  HUB_ADMIN_VEHICLES: '5af4d894',
  HUB_ADMIN_BATTERIES: '72e7595e',
  HUB_ADMIN_SERVICE_TICKETS: '1b8967d3',
  HUB_ADMIN_EXECUTIVES: 'e7b01777',
  HUB_ADMIN_APPROVALS: 'fcfb42b2',
  HUB_ADMIN_TEAMS: 'de31f414',

  // Other
  DEPLOYED_VEHICLES: '02d9dba4',
  SUPER_ADMIN_RENTAL_EVENTS: '369946ef',

  // Common for all
  ACCOUNT: '04b0a0f6',
  SECURITY: '13eb3bd2',
};

export default apiPages;
