import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

// Function to get the last n months with years as options
export const getLastNMonthsAsOptions = (n) => {
  const months = [];
  let currentDate = dayjs(); // Get current date using dayjs
  for (let i = 0; i < n; i++) {
    const month = currentDate.format('MMMM');
    const year = currentDate.year();
    const label = `${month} ${year}`;
    const value = i;
    months.unshift({ label, value }); // Add month-year to the beginning of array
    currentDate = currentDate.subtract(1, 'month'); // Move to previous month
  }
  return months.reverse();
};

export const convertLocalToUTC = (localDateTime) => {
  // Parse the local date and time string using dayjs
  const localDate = dayjs(localDateTime);

  // Convert local date and time to UTC
  const utcDate = localDate.utc();

  // Get the epoch time in milliseconds for the UTC date
  const epochTimeUTC = utcDate.valueOf();

  // Return the UTC date and time along with the epoch time in UTC
  return {
    utcDateTime: utcDate.format(), // UTC date and time string
    epochTimeUTC: epochTimeUTC, // Epoch time in UTC
  };
};

// Function to get the start and end date of the ith month
export const getStartEndDateTime = (i) => {
  const currentDate = dayjs();

  const selectedDate = currentDate.subtract(i, 'month');

  const startOfMonthLocalDateTime = selectedDate.startOf('month').format();
  const startOfMonthLocalEpochTime = selectedDate.startOf('month').valueOf();

  const endOfMonthLocalDateTime = selectedDate.endOf('month').format();
  const endOfMonthLocalEpochTime = selectedDate.endOf('month').valueOf();

  const { epochTimeUTC: startOfMonthUTCEpochTime, utcDateTime: startOfMonthUTCDateTime } =
    convertLocalToUTC(startOfMonthLocalDateTime);

  const { epochTimeUTC: endOfMonthUTCEpochTime, utcDateTime: endOfMonthUTCDateTime } =
    convertLocalToUTC(endOfMonthLocalDateTime);

  return {
    startOfMonth: {
      local: startOfMonthLocalDateTime,
      localEpoch: startOfMonthLocalEpochTime,
      utc: startOfMonthUTCDateTime,
      utcEpoch: startOfMonthUTCEpochTime,
    },
    endOfMonth: {
      local: endOfMonthLocalDateTime,
      localEpoch: endOfMonthLocalEpochTime,
      utc: endOfMonthUTCDateTime,
      utcEpoch: endOfMonthUTCEpochTime,
    },
  };
};

// Function to get days in the month relative to j from now
export const getDaysInMonthRelativeToJ = (j, dateFormat = 'DD-MM-YY') => {
  const currentDate = dayjs();
  const selectedDate = currentDate.subtract(j, 'month');
  const daysInMonth = selectedDate.daysInMonth();
  const days = [];
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(selectedDate.date(i).format(dateFormat));
  }
  return days;
};

// Function to get the last n days
export const getLastNDays = (n, dateFormat = 'DD-MM-YY') => {
  const currentDate = dayjs();
  const days = [];
  for (let i = 0; i < n; i++) {
    const day = currentDate.subtract(i, 'day').format(dateFormat);
    days.unshift(day); // Add day to the beginning of array
  }
  return days;
};

// Function to get hours in the day relative to j from now
export const getHoursInDayRelativeToJ = (j, dateFormat = 'HH:mm') => {
  const currentDate = dayjs();
  const selectedDate = currentDate.subtract(j, 'day');
  const hoursInDay = [];
  for (let i = 0; i < 24; i++) {
    const hour = selectedDate.hour(i).format(dateFormat);
    hoursInDay.push(hour);
  }
  return hoursInDay;
};
