import { isRejectedWithValue } from '@reduxjs/toolkit';
import { errorsActions } from './errors';

const rtkQueryErrorsMiddleware = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    api.dispatch(errorsActions.push({ status: action.payload?.status, data: action.payload.data }));
  }
  return next(action);
};

export default rtkQueryErrorsMiddleware;
