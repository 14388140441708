import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material';
import theme from '../theme';

function COSThemeProvider({ children }) {
  return <ThemeProvider theme={theme()}>{children}</ThemeProvider>;
}

COSThemeProvider.propTypes = { children: PropTypes.node.isRequired };

export default COSThemeProvider;
