import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';
import { usePrevious } from '../hooks';
import { errorsActions } from '../store/errors';

export default () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const error = useSelector((state) => state.errors.errors.find(() => true));
  const previousError = usePrevious(error);

  useEffect(() => {
    if (error?.status === 401) {
      localStorage.clear();
      navigate('/login', { replace: true });
      window.location.reload();
    }
  }, [error]);

  if (error?.status === 401) return false;

  if (error?.status === 200 || previousError?.status === 200) {
    return (
      <Snackbar
        key="success"
        open={Boolean(error)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => dispatch(errorsActions.pop())}
      >
        <Alert
          sx={{ '.MuiIconButton-root': { color: '#fff' } }}
          elevation={6}
          onClose={() => dispatch(errorsActions.pop())}
          severity="success"
          variant="filled"
        >
          {error?.data || previousError?.data}
        </Alert>
      </Snackbar>
    );
  }

  if (error || previousError) {
    return (
      <Snackbar
        key="failed"
        open={Boolean(error)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => dispatch(errorsActions.pop())}
      >
        <Alert
          sx={{ '.MuiIconButton-root': { color: '#fff' } }}
          elevation={6}
          onClose={() => dispatch(errorsActions.pop())}
          severity="error"
          variant="filled"
        >
          {error?.data || previousError?.data}
        </Alert>
      </Snackbar>
    );
  }
  return false;
};

export const ErrorHandler1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const error = useSelector((state) => state.errors.errors.find(() => true));
  const previousError = usePrevious(error);

  useEffect(() => {
    if (error?.status === 401) {
      navigate('/login', { replace: true });
      window.location.reload();
    }
  }, [error]);

  if (error?.status === 401) return false;

  if (error?.status === 200 || previousError?.status === 200) {
    return (
      <Snackbar
        key="success"
        open={Boolean(error)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => dispatch(errorsActions.pop())}
      >
        <Alert
          sx={{ '.MuiIconButton-root': { color: '#fff' } }}
          elevation={6}
          onClose={() => dispatch(errorsActions.pop())}
          severity="success"
          variant="filled"
        >
          {error?.data || previousError?.data}
        </Alert>
      </Snackbar>
    );
  }

  if (error || previousError) {
    return (
      <Dialog open={Boolean(error)} onClose={() => dispatch(errorsActions.pop())}>
        <DialogContent sx={{ width: { sm: '500px' }, backgroundColor: '' }}>
          <DialogTitle
            sx={{ fontSize: 64, fontWeight: 900 }}
            color="#ff0000"
            id="alert-dialog-title"
          >
            {error?.data || previousError?.data}
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button variant="confirm" onClick={() => dispatch(errorsActions.pop())}>
            I understand
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return false;
};
