import { useMemo } from 'react';
import { useTypographyLocalization } from '../context/TypographyLocalizationProvider';

export default () => {
  const { language, languages } = useTypographyLocalization();
  const { data } = languages[language];
  return useMemo(() => (key) => data[key] || key, [data]);
};

export const useTranslationKeys = (predicate) => {
  const { language, languages } = useTypographyLocalization();
  const { data } = languages[language];
  return Object.keys(data).filter(predicate);
};
