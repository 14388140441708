export const exportToCSV = (data, fileName) => {
  const header = Object.keys(data[0]).join(',');
  const body = data.map((row) => Object.values(row).join(',')).join('\n');

  const csv = `${header}\n${body}`;
  const uri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);

  const link = document.createElement('a');
  link.href = uri;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportToExcel = (data, fileName) => {
  const header = Object.keys(data[0]).join('\t') + '\n';
  const body = data.map((row) => Object.values(row).join('\t')).join('\n');
  const csvContent = header + body;

  const blob = new Blob([csvContent], { type: 'application/vnd.ms-excel;charset=utf-8;' });
  const link = document.createElement('a');

  if (navigator.msSaveBlob) {
    // For Internet Explorer
    navigator.msSaveBlob(blob, `${fileName}.xls`);
  } else {
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.xls`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const exportToJSON = (data, fileName) => {
  const jsonData = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonData], { type: 'application/json' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${fileName}.json`;
  link.click();
};
