import React from 'react';
import { Avatar, Box, Drawer, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../../assets/logos/headerLogo.webp';
import { NavMenuOptions } from './AppSideBar';

const AppMobileMenu = () => {
  const [open, setOpen] = React.useState();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
    setOpen(open);
  };

  return (
    <React.Fragment>
      <IconButton onClick={toggleDrawer(true)} sx={{ display: { md: 'none' } }}>
        <MenuIcon />
      </IconButton>

      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <Box minWidth={(t) => t.dimentions.mobileDawerWidth}>
          <Box borderBottom="1px solid #EBEBEB" padding="16px">
            <Avatar
              sx={{ width: '100%', height: '65px', '& img': { objectFit: 'contain' } }}
              variant="square"
              src={logo}
              alt="Logo"
            />
          </Box>
          <NavMenuOptions handleOnClick={toggleDrawer(false)} isMobile />
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default AppMobileMenu;
