import msilApi from '../../msilApi';
import onCacheEntryAdded from '../../utils/onCacheEntryAdded';
export const userSlice = msilApi.injectEndpoints({
  endpoints: (builder) => ({
    resetPassword: builder.mutation({
      query: ({ currentPassword, newPassword, cancelToken }) => ({
        url: 'user/updatePassword',
        data: { currentPassword: currentPassword, newPassword },
        cancelToken,
        method: 'PUT',
      }),
      onCacheEntryAdded,
    }),
    getUserDetails: builder.query({
      query: () => ({
        url: 'user',
        method: 'GET',
      }),
      keepUnusedDataFor: 30,
      transformResponse: (response) => response.data,
      providesTags: ['USER'],
    }),
    updateUser: builder.mutation({
      query: ({ body, cancelToken }) => ({
        url: 'user/updateMobile',
        method: 'PUT',
        data: { ...body },
        cancelToken,
      }),
      invalidatesTags: ['USER'],
      onCacheEntryAdded,
    }),
    uploadProfileImage: builder.mutation({
      query: ({ image, cancelToken }) => ({
        method: 'PUT',
        url: 'user/updateProfile',
        data: image,
        cancelToken,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      onCacheEntryAdded,
      invalidatesTags: ['USER'],
    }),
  }),
  tagTypes: ['USER'],
});

export const {
  useResetPasswordMutation,
  useGetUserDetailsQuery,
  useUpdateUserMutation,
  useUploadProfileImageMutation,
} = userSlice;
