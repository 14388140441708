import React from 'react';

export const RadioIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
    <circle cx="8.38942" cy="9.21608" r="7.96995" stroke="#AEAEB2" strokeWidth="0.838942" />
  </svg>
);

export const RadioCheckedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
    <circle cx="8.38942" cy="9.30299" r="7.96995" stroke="#3DC4C3" strokeWidth="0.838942" />
    <circle cx="8.39002" cy="9.30311" r="4.19471" fill="#3DC4C3" />
  </svg>
);
